import React from 'react'
import { Accordion } from 'react-bootstrap'

import { AccordionItemWrapper } from './AccordionItem.styled'

type AccordionItemType = {
    eventKey: string
    header: JSX.Element | JSX.Element[]
    onClick?: () => void
    body?: JSX.Element | JSX.Element[]
}

export default function AccordionItem(accordionObject: AccordionItemType) {
    const { eventKey, header, onClick, body } = accordionObject

    return (
        <AccordionItemWrapper>
            <Accordion.Item
                eventKey={eventKey}
                className={!body ? 'no-toggle' : ''}
            >
                <Accordion.Header onClick={onClick}>{header}</Accordion.Header>
                {body && <Accordion.Body>{body}</Accordion.Body>}
            </Accordion.Item>
        </AccordionItemWrapper>
    )
}
