import React, { useEffect, useState } from 'react'

import {
    addLicenseNotificationConfig,
    deleteLicenseNotificationConfig,
    editLicenseNotificationConfig,
    getLicenseNotificationConfigs,
} from 'api/licenseNotificationConfigs'
import { ConfirmModal } from 'apps/shared/components/Modals'
import {
    LicenseNotification,
    LicenseNotificationConfigType,
    RecordType,
} from 'apps/vendor/interfaces/subscriptions'
import useToast from 'hooks/useToast'

import { AddConfigurationButton } from './ActionsTab.styled'
import NotificationConfigCard from './components'

interface ActionsTabProps {
    licenseId: number
    isManualLicense: boolean
}

interface NotificationOption {
    type: LicenseNotification
    leadDays: number | null
}

export default function ActionsTab(defaultProps: ActionsTabProps) {
    const { licenseId, isManualLicense } = defaultProps
    const [newCardOpen, setNewCardOpen] = useState(false)

    const [notificationConfigs, setNotificationConfigs] = useState<
        LicenseNotificationConfigType[] | null
    >(null)

    const [selectedNotificationConfig, setSelectedNotificationConfig] =
        useState<LicenseNotificationConfigType | null>(null)

    const [selectedEmails, setSelectedEmails] = useState<string[]>([])

    const [selectedNotificationOptions, setSelectedNotificationOptions] =
        useState<NotificationOption[]>([])
    const [showDeleteConfigModal, setShowDeleteConfigModal] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    const { successToast, errorToast } = useToast()

    const updateNotificationConfigs = () => {
        getLicenseNotificationConfigs(licenseId).then((resp) => {
            setNotificationConfigs(resp.data.reverse())
        })
    }

    useEffect(() => {
        updateNotificationConfigs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedNotificationConfig) {
            setSelectedEmails(
                Array.from(
                    new Set(
                        // eslint-disable-next-line max-len
                        selectedNotificationConfig.license_notification_config_records.map(
                            (record) => record.email,
                        ),
                    ),
                ),
            )

            setSelectedNotificationOptions(
                // eslint-disable-next-line max-len
                selectedNotificationConfig.license_notification_config_records.map(
                    (record) => ({
                        type: record.type,
                        leadDays: record.lead_days,
                    }),
                ),
            )
        } else {
            setSelectedEmails([])
            setSelectedNotificationOptions([])
        }
    }, [selectedNotificationConfig])

    useEffect(() => {
        let isDirty = true
        let isValid = false

        // Check if we have the required fields
        const hasRequiredFields =
            selectedEmails.length > 0 && selectedNotificationOptions.length > 0

        if (selectedNotificationConfig) {
            const initialEmails = Array.from(
                new Set(
                    // eslint-disable-next-line max-len
                    selectedNotificationConfig.license_notification_config_records.map(
                        (record) => record.email,
                    ),
                ),
            )

            const initialOptions =
                // eslint-disable-next-line max-len
                selectedNotificationConfig.license_notification_config_records.map(
                    (record) => ({
                        type: record.type,
                        leadDays: record.lead_days,
                    }),
                )

            const emailsChanged =
                selectedEmails.length !== initialEmails.length ||
                selectedEmails.some((email) => !initialEmails.includes(email))

            const optionsChanged =
                selectedNotificationOptions.length !== initialOptions.length ||
                selectedNotificationOptions.some(
                    (option) =>
                        !initialOptions.some(
                            (initialOpt) =>
                                initialOpt.type === option.type &&
                                initialOpt.leadDays === option.leadDays,
                        ),
                )

            isDirty = emailsChanged || optionsChanged
            isValid = hasRequiredFields
        } else {
            isValid = hasRequiredFields
        }

        setCanSubmit(isDirty && isValid)
    }, [
        selectedEmails,
        selectedNotificationOptions,
        selectedNotificationConfig,
    ])

    const prepareNotificationRecords = (): RecordType[] => {
        // If no existing config is selected, create new records
        if (!selectedNotificationConfig) {
            return selectedEmails.flatMap((email) =>
                selectedNotificationOptions.map((option) => ({
                    email,
                    type: option.type,
                    lead_days: option.leadDays,
                })),
            )
        }

        // Create a map for existing records
        const existingRecords = new Map(
            selectedNotificationConfig.license_notification_config_records.map(
                (record) => [
                    `${record.email}-${record.type}-${record.lead_days}`,
                    record,
                ],
            ),
        )

        // Use reduce to create a Set of unique records
        const recordSet = selectedNotificationOptions.reduce((set, option) => {
            selectedEmails.forEach((email) => {
                const key = `${email}-${option.type}-${option.leadDays}`
                const existingRecord = existingRecords.get(key)

                const record: RecordType = {
                    email,
                    type: option.type,
                    lead_days: option.leadDays,
                    ...(existingRecord && {
                        // If record exists, provide the ID
                        id: existingRecord.id,
                    }),
                }

                // eslint-disable-next-line max-len
                // Use stringified JSON to compare records since objects are not comparable
                set.add(JSON.stringify(record))
            })

            return set
        }, new Set<string>())

        // Convert Set back to an array of objects
        return Array.from(
            Array.from(recordSet).map((record) => JSON.parse(record)),
        )
    }

    const handleSubmit = () => {
        const notificationConfigData: LicenseNotificationConfigType = {
            license_notification_config_records: prepareNotificationRecords(),
        }

        const apiCall = selectedNotificationConfig?.id
            ? editLicenseNotificationConfig(
                  licenseId,
                  selectedNotificationConfig.id,
                  notificationConfigData,
              )
            : addLicenseNotificationConfig(licenseId, notificationConfigData)

        apiCall
            .then(() => {
                setSelectedNotificationConfig(null)
                setNewCardOpen(false)
                updateNotificationConfigs()
                setSelectedEmails([])
                setSelectedNotificationOptions([])

                successToast(
                    selectedNotificationConfig
                        ? 'Notification configuration updated'
                        : 'Notification configuration added',
                )
            })
            .catch((err) => {
                if (err.response.data.detail.includes('email')) {
                    return errorToast(
                        // eslint-disable-next-line max-len
                        'Please provide a valid email address for all recipients',
                    )
                }

                if (err.response.status !== 500) {
                    return errorToast(
                        selectedNotificationConfig
                            ? 'Failed to update notification configuration'
                            : 'Failed to add notification configuration',
                    )
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const onNotificationCardDelete = (
        config: LicenseNotificationConfigType,
    ) => {
        setSelectedNotificationConfig(config)
        setShowDeleteConfigModal(true)
    }

    const handleDeleteConfig = () => {
        if (selectedNotificationConfig) {
            if (selectedNotificationConfig?.id !== undefined) {
                deleteLicenseNotificationConfig(
                    licenseId,
                    selectedNotificationConfig.id,
                )
                    .then(() => {
                        setSelectedNotificationConfig(null)
                        updateNotificationConfigs()
                        setShowDeleteConfigModal(false)
                        successToast('Notification config deleted successfully')
                    })
                    .catch(() => {
                        return errorToast(
                            // eslint-disable-next-line max-len
                            'Unknown error. Please try again and contact Sharlic support if error persists',
                        )
                    })
            }
        }
    }

    const handleCloseCard = () => {
        setSelectedNotificationConfig(null)
        setNewCardOpen(false)
        setSelectedEmails([])
        setSelectedNotificationOptions([])
    }

    const renderConfirmModalBody = () => {
        return (
            <>
                <section>
                    You are about to delete the notification config.
                </section>
                <br />
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </>
        )
    }

    const handleNotificationOptions = (
        type: LicenseNotification,
        leadDays: number | null,
    ) => {
        setSelectedNotificationOptions((prevOptions) => {
            if (
                prevOptions.some(
                    (option) =>
                        option.type === type && option.leadDays === leadDays,
                )
            ) {
                return prevOptions.filter(
                    (option) =>
                        !(option.type === type && option.leadDays === leadDays),
                )
            }

            return [...prevOptions, { type, leadDays }]
        })
    }

    const renderExistingConfigs = () => {
        if (!notificationConfigs) {
            return null
        }

        return notificationConfigs?.map((config) => {
            const emails = Array.from(
                new Set(
                    config.license_notification_config_records.map(
                        (record) => record.email,
                    ),
                ),
            )

            const notificationOptions =
                config.license_notification_config_records.map((record) => ({
                    type: record.type,
                    leadDays: record.lead_days,
                }))

            const isEditing = selectedNotificationConfig?.id === config.id

            const isDisabled =
                newCardOpen ||
                (selectedNotificationConfig && !isEditing) ||
                false

            return (
                <NotificationConfigCard
                    key={config.id}
                    emails={isEditing ? selectedEmails : emails}
                    notificationOptions={
                        isEditing
                            ? selectedNotificationOptions
                            : notificationOptions
                    }
                    isManualLicense={isManualLicense}
                    onEdit={() => {
                        setSelectedNotificationConfig(config)
                        setSelectedEmails(emails)
                        setSelectedNotificationOptions(notificationOptions)
                    }}
                    onDelete={() => onNotificationCardDelete(config)}
                    disabled={isDisabled}
                    isEditing={isEditing}
                    canSubmit={canSubmit}
                    onEmailsChange={(newEmails) =>
                        setSelectedEmails(
                            newEmails.filter((email) => email.trim()),
                        )
                    }
                    onNotificationOptionChange={handleNotificationOptions}
                    onSubmit={handleSubmit}
                    onCancel={handleCloseCard}
                />
            )
        })
    }

    return (
        <div className="scrollable-content">
            <AddConfigurationButton
                onClick={() => setNewCardOpen(true)}
                disabled={newCardOpen || selectedNotificationConfig !== null}
            >
                <i className="fa-regular fa-circle-plus" />
                <span>Add notification configuration</span>
            </AddConfigurationButton>
            {newCardOpen && (
                <NotificationConfigCard
                    emails={selectedEmails}
                    notificationOptions={selectedNotificationOptions}
                    isManualLicense={isManualLicense}
                    isEditing
                    canSubmit={canSubmit}
                    onEmailsChange={(emails) =>
                        setSelectedEmails(
                            emails.filter((email) => email.trim()),
                        )
                    }
                    onNotificationOptionChange={handleNotificationOptions}
                    onSubmit={handleSubmit}
                    onCancel={handleCloseCard}
                />
            )}
            {renderExistingConfigs()}
            {showDeleteConfigModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteConfigModal(false)
                        setSelectedNotificationConfig(null)
                    }}
                    onSubmit={() => handleDeleteConfig()}
                    customSubmitText="Delete"
                    body={renderConfirmModalBody()}
                    isCentered
                    deleteButton
                    canSubmit
                />
            )}
        </div>
    )
}
