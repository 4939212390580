/**
 * This function takes a string and capitalizes the first letter
 * of the string and replaces all underscores with spaces.
 * @param {string} value - The string to capitalize and format.
 * @param {boolean} exclude - If true, the function will skip
 * the formatting and return the original string.
 * @returns {string} - The formatted string.
 */
export default function capitalizeAndFormat(
    value: string,
    exclude?: boolean,
): string {
    if (!value || exclude) return value
    const formattedValue = value.replace(/_/g, ' ')

    return (
        formattedValue.charAt(0).toUpperCase() +
        formattedValue.slice(1).toLowerCase()
    )
}
