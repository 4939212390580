import styled from 'styled-components'
import { secondary } from 'utils/colors'

export const SidebarFooter = styled.div`
    border-top: 1px solid ${secondary.whisperWhite};
    padding: 1.2rem;
    display: flex;
    justify-content: end;
`

export const AlertRow = styled.div`
    > * {
        margin-bottom: 0;
    }
`
