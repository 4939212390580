import React from 'react'
import { Button } from 'react-bootstrap'

export default function CancelButton(props: { onClick: () => void }) {
    const { onClick } = props

    return (
        <Button variant="light" onClick={onClick}>
            Cancel
        </Button>
    )
}
