import React from 'react'
import { ReactMultiEmail } from 'react-multi-email'
import 'react-multi-email/dist/style.css'

interface MultipleEmailProps {
    value: string[]
    placeholder?: string
    onChange: (value: string[]) => void
    inputRef?: React.RefObject<HTMLInputElement>
}

export default function MultipleEmailInput(defaultProps: MultipleEmailProps) {
    const { value, placeholder, onChange, inputRef } = defaultProps

    const getEmailLabel = (email: string, index: number, removeEmail: any) => {
        return (
            <div data-tag key={index}>
                <div data-tag-item>{email}</div>
                <span
                    data-tag-handle
                    role="button"
                    tabIndex={0}
                    onClick={() => removeEmail(index)}
                    onKeyDown={() => removeEmail(index)}
                >
                    ×
                </span>
            </div>
        )
    }

    return (
        <ReactMultiEmail
            autoFocus
            onFocus={() => inputRef?.current?.focus()}
            placeholder={placeholder}
            emails={value}
            onChange={(emails: string[]) => {
                onChange(emails)
            }}
            allowDuplicate={false}
            getLabel={getEmailLabel}
        />
    )
}
