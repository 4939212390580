import React from 'react'
import { Table } from 'react-bootstrap'

type BootstrapTableType = {
    headers?: any[]
    data: any[]
    accessors: any[]
    striped?: boolean
    onRowClicked?: (item: any) => void
    centeredHeaders?: string[]
    rightAlignedHeaders?: string[]
}

export default function BootstrapTable(defaultProps: BootstrapTableType) {
    const { headers, data, accessors, onRowClicked, striped } = defaultProps

    const getValueByAccessor = (obj: any, accessor: string) => {
        if (!accessor) {
            return ''
        }

        const properties = accessor.split('.')
        let value = obj

        for (let i = 0; i < properties.length; i += 1) {
            const property = properties[i]

            if (property.includes('[') && property.includes(']')) {
                const [arrayProp, index] = property.split('[')
                const arrayIndex = parseInt(index.replace(']', ''), 10)
                value = value[arrayProp][arrayIndex]
            } else {
                value = value[property]
            }
            if (value === undefined) {
                return ''
            }
        }

        return value
    }

    const getClassname = (
        item: string,
        centeredItems: string[],
        rightJustifiedItems: string[],
        farRightJustifiedItems?: string[],
    ) => {
        if (centeredItems.includes(item)) {
            return 'centered'
        }

        if (rightJustifiedItems.includes(item)) {
            return 'right-justified'
        }

        if (farRightJustifiedItems && farRightJustifiedItems.includes(item)) {
            return 'far-right-justified'
        }

        return ''
    }

    const getHeaderItemClassname = (header: string) => {
        const centeredItems = ['Status', 'Active']

        const rightJustifiedItems = [
            'Total price',
            'Quantity',
            'Unit MSRP',
            'Total MSRP',
        ]

        return getClassname(header, centeredItems, rightJustifiedItems)
    }

    const getBodyItemClassname = (accessor: string) => {
        const centeredItems = ['status', 'active', 'number']

        const rightJustifiedItems = [
            'total_price',
            'quantity',
            'unitMsrp',
            'totalArticleMSRP',
        ]

        const farRightJustifiedItems = ['infoQuantity', 'options', 'pdf']

        return getClassname(
            accessor,
            centeredItems,
            rightJustifiedItems,
            farRightJustifiedItems,
        )
    }

    return (
        <Table striped={striped}>
            <thead>
                <tr>
                    {headers?.map((header) => {
                        return (
                            <td
                                key={header}
                                className={getHeaderItemClassname(header)}
                            >
                                {header}
                            </td>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {data &&
                    data.map((item, i) => {
                        return (
                            <tr
                                className={onRowClicked ? 'clickable-row' : ''}
                                key={item.id || i}
                                onClick={() =>
                                    onRowClicked && onRowClicked(item)
                                }
                            >
                                {accessors.map((accessor, index) => {
                                    return (
                                        <td
                                            key={accessor}
                                            className={getBodyItemClassname(
                                                accessor,
                                            )}
                                        >
                                            <span>
                                                {getValueByAccessor(
                                                    item,
                                                    accessors[index],
                                                )}
                                            </span>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
            </tbody>
        </Table>
    )
}
