import styled from 'styled-components'
import { secondary } from 'utils/colors'

// Theme constants
const BREAKPOINTS = {
    MOBILE: '575px',
    TABLET: '1199px',
    DESKTOP: '1200px',
} as const

const SIZES = {
    NAVBAR_HEIGHT: '140px',
    SIDEBAR_WIDTH: '280px',
    USER_ICON_SIZE: '55px',
    TOGGLE_SIZE: '2rem',
} as const

const TRANSITIONS = {
    DEFAULT: 'all 0.3s linear',
} as const

// Main styled components
export const NavbarWrapper = styled.div`
    padding-top: 1rem;
    .collapsing {
        transition: none;
    }

    @media screen and (min-width: ${BREAKPOINTS.MOBILE}) {
        box-sizing: border-box;
        border: 2px solid ${secondary.whisperWhite};
        border-top: none;
        border-left: none;
        position: relative;

        > * {
            padding: 0;
            height: 100%;
            width: 100%;
            overflow-y: auto;

            > * {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        .navbar-nav {
            display: flex;
            flex-direction: column;
        }

        .navbar-brand {
            margin: 0;
            width: 100%;
            padding-top: 0;
        }

        .navbar-expand-xl .navbar-nav .nav-link {
            padding: 0;
        }
    }

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        padding: 0;
        > * {
            padding: 0;
            width: 100vw;
            z-index: 500;
            position: fixed;

            > * {
                background-color: white;
                position: relative;
                height: 100%;

                &:nth-child(2) {
                    overflow-y: auto;
                    height: calc(100vh - 66px);

                    > *:nth-child(2) {
                        min-height: 600px;
                    }
                }
            }
        }
    }
`

export const NavRow = styled.div`
    height: 100%;
    width: ${SIZES.SIDEBAR_WIDTH};
    margin-top: -5px;

    @media screen and (max-width: ${BREAKPOINTS.MOBILE}) {
        width: 100%;
    }
`

export const ButtonText = styled.div`
    display: flex;
    flex-direction: row;
`

export const StyledToggle = styled.button<{ open: boolean }>`
    width: ${SIZES.TOGGLE_SIZE};
    height: ${SIZES.TOGGLE_SIZE};
    position: fixed;
    top: 16px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: transparent;
    border: none;
    cursor: pointer;

    @media (min-width: ${BREAKPOINTS.DESKTOP}) {
        display: none;
    }

    @media (max-width: ${BREAKPOINTS.TABLET}) {
        left: 0;
    }

    @media (max-width: ${BREAKPOINTS.MOBILE}) {
        right: 10px;
    }

    div {
        width: ${SIZES.TOGGLE_SIZE};
        height: 0.25rem;
        background-color: ${({ open }) =>
            open ? secondary.lightGray : secondary.paleBlue};
        border-radius: 10px;
        transform-origin: 1.8px;
        transition: ${TRANSITIONS.DEFAULT};

        &:nth-child(1) {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        &:nth-child(2) {
            transform: ${({ open }) =>
                open ? 'translateX(100%)' : 'translateX(0)'};
            opacity: ${({ open }) => (open ? 0 : 1)};
        }

        &:nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`
