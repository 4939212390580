import { FormLabel } from 'apps/shared/shared.styled'
import styled, { css } from 'styled-components'
import { primary, secondary } from 'utils/colors'

const commonFormStyles = css`
    @media screen and (max-width: 1023px) {
        width: 50vw;
        flex-grow: 1;
        border-radius: 5px;
    }
    @media screen and (max-width: 700px) {
        width: 350px;
    }
    @media screen and (max-width: 400px) {
        width: 88vw;
    }

    width: 28vw;
    max-width: 550px;
`

export const RegistrationPageWrapper = styled.div`
    input {
        font-size: 16px !important;
    }

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 32vw;
        > :last-child {
            background-color: white;
            height: 100vh;
        }
    }
    @media screen and (max-width: 1023px) {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    min-height: 800px;
`

export const Image = styled.div`
    flex: 1;
    position: relative;

    #sharlicWhiteLogo,
    #sharlicLogo {
        z-index: 5;
        height: 16vh;
        margin: 25px 0 0 25px;
    }

    #sharlicWhiteLogo {
        @media screen and (max-width: 1023px) {
            display: none;
        }
    }

    #sharlicLogo {
        @media screen and (min-width: 1024px) {
            display: none;
        }
        @media screen and (max-width: 1023px) {
            height: 80%;
            min-width: 171px;
        }
    }

    #bubbles {
        z-index: -20;
        height: 100vh;
        position: absolute;
        left: 0;
        background-repeat: calc();

        @media screen and (max-width: 1023px) {
            display: none;
        }
    }

    @media screen and (max-width: 1023px) {
        height: 6em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export const InformationWrapper = styled.div`
    color: ${primary.darkBlue};
    padding: 24px 16px 0 16px;
    display: grid;
    height: fit-content;
    width: 30vw;
    min-width: 300px;
    max-width: 550px;
    grid-template-rows: 3fr 1fr;

    .text-container {
        padding-top: 1em;
        text-align: center;

        h1 {
            font-size: 2em;
            margin-bottom: 0.5em;
            color: ${primary.darkBlue};
        }

        p {
            font-size: 1.25em;
            line-height: 1.6;
            color: ${primary.darkBlue};
            margin-bottom: 2rem;
        }
    }

    button {
        max-height: 48px;
        width: 100%;
    }
`

export const RegistrationContentContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
    overflow: hidden;
    justify-content: center;

    h1 {
        text-align: center;
        font-size: 1.7rem !important;
        margin-bottom: 1em;
    }

    > :first-child {
        overflow: auto;
    }

    > .registration-footer {
        margin-top: 2px;
        grid-row: 2;
        align-self: end;
        width: 100%;
    }

    @media screen and (min-width: 1024px) {
        > :first-child {
            margin-top: 5em;
        }
    }

    @media screen and (max-width: 1023px) {
        > .registration-footer {
            margin-bottom: 40px;
        }
    }
`

export const RegisterForm = styled.div`
    ${commonFormStyles}

    > * {
        line-height: 2;
    }

    .form-control,
    .form-control * {
        margin-bottom: 6px;
        line-height: 2;
    }

    .phone-input {
        margin-bottom: 6px !important;
    }

    .hide-show-pw {
        left: calc(100% - 2.4em);
        border: none;
        background: transparent;
    }

    > form {
        padding: 0 1em;
    }
`

export const ConfirmationFormWrapper = styled.div`
    ${commonFormStyles}
    width: max;

    form {
        min-height: 40px;
        overflow-y: auto;
        border: 1px solid ${secondary.whisperWhite};
        border-radius: 5px;
        padding: 1em;
    }

    * {
        margin-bottom: 6px;
    }

    h2 {
        color: ${primary.darkGray};
        text-align: center;
        margin-bottom: 1em;
        font-size: 16px;
    }

    h2:not(.personalInfo) {
        margin-top: 1em;
    }

    h3 {
        color: ${primary.gray};
        font-size: 12px;
    }
`

export const Footer = styled.div`
    grid-row: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    height: auto;
    min-height: 50px;

    p {
        font-size: 12px;
        text-align: center;
        color: ${primary.darkGray};
        a {
            color: ${primary.blue};
            font-weight: 600;
        }
    }
`

export const ProgressBar = styled.div`
    margin-top: 1em;
    display: flex;
    justify-content: center;
    height: 46px;

    ul {
        display: flex;
        justify-content: center;
        padding: 0;
        width: 100%;
    }
`

export const ProgressCirclesContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 0;
    width: 100%;

    button {
        float: left;
        text-align: center;
        width: 30px;
        height: 30px;
        border: 2px solid ${primary.lightGray};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: white;
        color: ${primary.lightGray};
        font-weight: bold;
        transition: border 0.5s ease;
        flex-grow: 0;
    }

    button:not(:disabled):not(.selected):hover {
        cursor: pointer;
        background-color: ${primary.lightBlue};
        border: none;
        color: white;
    }
    button.selected {
        background-color: ${primary.lightBlue};
        box-shadow: 0 0 1px 2px ${primary.blue};
        border: 3px solid ${primary.lightBlue};
        color: white;
    }
    button.valid {
        border: 2px solid ${primary.darkGreen};
        background-color: ${primary.darkGreen};
    }
    button.prevValid {
        border: 2px solid ${primary.lightBlue};
    }

    button:disabled {
        background-color: ${primary.lightGray};
        color: white;
        box-shadow: none;
    }

    div {
        align-self: center;
        height: 2px;
        width: 60px;
        flex-grow: 1;
        margin: 0 4px;
        background: linear-gradient(
                to left,
                #cecece 50%,
                ${primary.darkGreen} 50%
            )
            right;
        background-size: 200% 100%;
        transition: all 0.5s ease;
    }

    div.valid {
        background-position: left;
    }
`

export const RegistrationButtonsContainer = styled.div`
    width: 100%;
    max-width: 320px;
    display: flex;
    justify-content: space-evenly;
    justify-self: center;

    button {
        width: 100%;
        height: 48px;
        border: none;
        font-size: 12px;
        margin: 0 10px;
    }

    @media screen and (max-width: 1023px) {
        width: 50vw;
        min-width: 250px;
    }
`

export const Space = styled.div<{ height?: string }>`
    height: 0;

    @media screen and (min-width: 1023px) {
        height: ${(props) => props.height || '2vh'};
    }
`

export const ExpiredTokenMessage = styled.h3`
    text-align: center;
    padding: 16px;
`

export const LabelAndInputContainer = styled.div`
    > ${FormLabel} {
        span {
            color: ${primary.gray};
        }
    }
    display: flex;
    flex-direction: column;
`

export const CompanyInfoSection = styled.li`
    list-style-type: none;
    margin: 10px 0 10px 0;
    position: relative;

    &.child-section:before {
        content: '';
        position: absolute;
        top: -10px;
        left: -30px;
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        width: 30px;
        height: 24px;
    }

    &.child-section:after {
        position: absolute;
        content: '';
        top: 13px;
        left: -30px;
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        width: 28px;
        height: 100%;
    }

    &.child-section:last-child:after {
        display: none;
    }

    > div {
        text-decoration: none;
        > label {
            margin-left: 0.5rem;
        }
    }

    > section {
        label:first-of-type {
            margin-left: 0.5rem;
        }
    }
`

export const CompanyInfoSectionHeader = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: ${primary.lightBlue};

    > p {
        margin: 0;
        margin-left: 1rem;
    }
`

export const CompanyInfoSectionContent = styled.ol`
    padding-left: 30px;
    margin-left: 10px;
`
