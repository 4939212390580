import React, { useEffect, useState } from 'react'
import { Offcanvas } from 'react-bootstrap'

import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'

import { AlertRow, SidebarFooter } from './Sidebar.styled'

interface SidebarProps {
    title: string | JSX.Element
    body: JSX.Element
    onClose: () => void
    onSubmit: () => void
    showSidebar: boolean
    canSubmit: boolean
    width?: string
    alert?: JSX.Element | null
    hideFooter?: boolean
}

export default function Sidebar(defaultProps: SidebarProps) {
    const {
        title,
        body,
        onClose,
        onSubmit,
        showSidebar,
        canSubmit,
        width,
        alert,
        hideFooter,
    } = defaultProps

    const [responsiveWidth, setResponsiveWidth] = useState(width || '20vw')
    const [show, setShow] = useState(showSidebar)

    // Sync the internal show state with the prop
    useEffect(() => {
        if (showSidebar) {
            setShow(true)
        }
    }, [showSidebar])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setResponsiveWidth('100vw')
            } else if (window.innerWidth < 1080) {
                setResponsiveWidth('80vw')
            } else if (window.innerWidth > 2560) {
                setResponsiveWidth('30vw')
            } else {
                setResponsiveWidth(width || '600px')
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => window.removeEventListener('resize', handleResize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClose = () => {
        setShow(false)
    }

    const handleExited = () => {
        onClose()
    }

    return (
        <Offcanvas
            show={show}
            onHide={handleClose}
            onExited={handleExited}
            placement="end"
            backdrop
            scroll
            style={{ width: responsiveWidth }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>{body}</Offcanvas.Body>
            {alert && <AlertRow>{alert}</AlertRow>}
            {!hideFooter && (
                <SidebarFooter>
                    <CancelSubmitFooter
                        onClose={handleClose}
                        onSubmit={onSubmit}
                        canSubmit={canSubmit}
                    />
                </SidebarFooter>
            )}
        </Offcanvas>
    )
}
