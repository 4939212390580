import React from 'react'

import { FormLabel, Switch } from 'apps/shared/shared.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import {
    LicenseStatus,
    Term,
    TimePeriod,
} from 'apps/vendor/interfaces/subscriptions'

import {
    LicenseBillingSection,
    MarginStructureContainer,
    SalesTierContainer,
} from './components'

interface BillingTabProps {
    handlers: {
        setSelectedTerm: (term: Term) => void
        setStatus: (status: LicenseStatus) => void
        setSelectedPaymentFrequency: (paymentFrequency: TimePeriod) => void
        setSelectedStartDate: (date: string) => void
        setSelectedInvoiceStartDate: (date: string) => void
        handleChangeManualInvoicing: (manualInvoicing: boolean) => void
    }
    states: {
        selectedTerm: Term
        partnerOwnedLicense: boolean
        status: LicenseStatus
        selectedPaymentFrequency: TimePeriod
        selectedStartDate: string
        selectedInvoiceStartDate: string
        manualInvoicing: boolean
        marginStructureInfo: any
        salesTierInfo: any
    }
}

export default function BillingTab(defaultProps: BillingTabProps) {
    const { handlers, states } = defaultProps

    const TermDisplay: { [key in Term]: string } = {
        [Term.ONE_MONTH]: 'One month',
        [Term.THREE_MONTHS]: 'Three months',
        [Term.SIX_MONTHS]: 'Six months',
        [Term.ONE_YEAR]: 'One year',
        [Term.EIGHTEEN_MONTHS]: 'Eighteen months',
        [Term.TWO_YEARS]: 'Two years',
        [Term.THREE_YEARS]: 'Three years',
        [Term.FIVE_YEARS]: 'Five years',
    }

    const handleSetTerm = (index: number) => {
        const newTerm = Object.values(Term)[index]
        handlers.setSelectedTerm(newTerm)
    }

    const renderTermDropdown = () => {
        const options = Object.values(Term).map((term, index) => ({
            value: index,
            label: TermDisplay[term],
        }))

        const selectedOption = options.find(
            (option) => option.label === TermDisplay[states.selectedTerm],
        )

        const value = selectedOption
            ? {
                  value: selectedOption.value,
                  label: selectedOption.label.toString(),
              }
            : null

        return (
            <>
                <FormLabel>Term</FormLabel>
                <SelectDropdown
                    options={options}
                    value={value}
                    onChange={handleSetTerm}
                    isDisabled={states.partnerOwnedLicense}
                />
            </>
        )
    }

    const handleSetPaymentFrequency = (index: number) => {
        handlers.setSelectedPaymentFrequency(Object.values(TimePeriod)[index])
    }

    const renderLicenseBillingSection = () => {
        const preselectedValues = {
            licenseStatus: states.status,
            term: states.selectedTerm,
            paymentFrequency: states.selectedPaymentFrequency,
            startDate: states.selectedStartDate,
            invoiceStartDate: states.selectedInvoiceStartDate,
            manualInvoicing: states.manualInvoicing,
        }

        return (
            <LicenseBillingSection
                preselectedValues={preselectedValues}
                partnerOwnedLicense={states.partnerOwnedLicense}
                onChangeStatus={handlers.setStatus}
                onChangePaymentFrequency={handleSetPaymentFrequency}
                onChangeStartDate={handlers.setSelectedStartDate}
                onChangeInvoiceStartDate={handlers.setSelectedInvoiceStartDate}
            />
        )
    }

    const renderMarginStructureSection = () => {
        if (states.manualInvoicing) {
            return null
        }

        return (
            <>
                <MarginStructureContainer {...states.marginStructureInfo} />
                <SalesTierContainer {...states.salesTierInfo} />
            </>
        )
    }

    const handleUpdateInvoicingMethod = (manualInvoiceSelected: boolean) => {
        if (!states.manualInvoicing) {
            handlers.setSelectedInvoiceStartDate(states.selectedStartDate)
        }

        handlers.handleChangeManualInvoicing(manualInvoiceSelected)
    }

    const renderAutomaticInvoicingSwitch = () => {
        return (
            <Switch
                id="automatic-billing-switch"
                checked={!states.manualInvoicing}
                label="Automatic billing"
                onChange={(e: any) =>
                    handleUpdateInvoicingMethod(!e.target.checked)
                }
                disabled={states.partnerOwnedLicense}
            />
        )
    }

    return (
        <>
            {renderAutomaticInvoicingSwitch()}
            {renderTermDropdown()}
            {renderLicenseBillingSection()}
            {renderMarginStructureSection()}
        </>
    )
}
