import styled from 'styled-components'
import { primary } from 'utils/colors'

export const NewItemButtonWrapper = styled.header`
    text-align: center;
    width: 100%;
    height: 0;
    position: relative;
    display: grid;
    margin-bottom: 24px;
    grid-template-columns: 3fr 2fr;
    align-items: center;

    > .absolute-button {
        position: absolute;
        right: 0;
        bottom: 6px;
        background-color: ${primary.blue};
        margin-bottom: -45px;
        border: none;
    }

    > .absolute-button:hover {
        background-color: ${primary.lightBlue};
    }

    > .absolute-button:active {
        background-color: ${primary.darkBlue} !important;
    }
`
