import React, { useEffect, useState, useMemo, useCallback } from 'react'

import { FormLabel } from 'apps/shared/shared.styled'
import capitalizeAndFormat from 'apps/shared/utils/capitalizeAndFormat'
import { SelectDropdown } from 'apps/vendor/components/Selects'

import { TextButton } from '../Buttons'
import {
    FilterAndSearchWrapper,
    FilterWrapper,
    SearchBarWrapper,
    TopRowContainer,
} from './SmartSearchAndFilter.styled'

// Function to transform values before display or comparison
export type TransformerFunction = (value: any) => string
/**
Main component props interface
* @param entities: Array of items to filter
* @param properties: Properties of T that can be filtered
* @param handleSetFilteredEntities: Callback to handle filtered results
* @param propertyTransformers: Transform property values before display
* @param labelTransformers: Transform filter labels
* @param recursiveSearch: Enable deep searching in nested objects
*/
interface SmartSearchAndFilterProps<T> {
    entities: T[]
    properties?: Array<keyof T>
    handleSetFilteredEntities: (value: T[]) => void
    propertyTransformers?: Partial<Record<keyof T, TransformerFunction>>
    labelTransformers?: Partial<Record<keyof T, TransformerFunction>>
    recursiveSearch?: boolean
}

// Properties to exclude from search
const ignoredProperties = ['id', 'related_licenses']

/**
 * Recursively searches through nested objects for a search value
 * @param obj - Object to search through
 * @param searchValue - Value to search for
 * @param propertyTransformers - Optional transformers for property values
 * @returns boolean indicating if search value was found
 */
const searchNestedObject = (
    obj: any,
    searchValue: string,
    propertyTransformers?: Record<string, TransformerFunction>,
): boolean => {
    if (!obj) return false

    // Handle primitive values (strings, numbers, etc.)
    if (typeof obj !== 'object') {
        const stringValue = String(obj)
        if (propertyTransformers && propertyTransformers[stringValue]) {
            return propertyTransformers[stringValue](obj)
                .toLowerCase()
                .includes(searchValue)
        }

        return stringValue.toLowerCase().includes(searchValue)
    }

    // Handle arrays by checking if any element matches
    if (Array.isArray(obj)) {
        return obj.some((item) =>
            searchNestedObject(item, searchValue, propertyTransformers),
        )
    }

    // Recursively search through object properties
    return Object.entries(obj).some(([key, value]) => {
        if (!value || ignoredProperties.includes(key)) return false

        // Handle nested objects and arrays
        if (typeof value === 'object' && value !== null) {
            return searchNestedObject(value, searchValue, propertyTransformers)
        }

        // Transform and search direct properties
        let searchableValue = value.toString()
        if (propertyTransformers && propertyTransformers[key]) {
            searchableValue = propertyTransformers[key](value)
        }

        return searchableValue.toLowerCase().includes(searchValue)
    })
}

/**
 * Filters array properties within an entity
 * and returns modified entity and match status
 * @param entity - Entity to process
 * @param searchValue - Value to search for
 * @param propertyTransformers - Optional transformers for property values
 * @returns Tuple of [filtered entity, boolean indicating if matches were found]
 */
const filterArrayProperties = <T extends Record<string, any>>(
    entity: T,
    searchValue: string,
    propertyTransformers?: Record<string, TransformerFunction>,
): [T, boolean] => {
    const result = { ...entity } as { [K in keyof T]: T[K] }
    let hasMatches = false

    Object.entries(result as unknown as [keyof T, T[keyof T]][]).forEach(
        ([key, value]) => {
            if (Array.isArray(value)) {
                const filteredArray = value.filter((item) =>
                    searchNestedObject(item, searchValue, propertyTransformers),
                ) as T[keyof T]

                ;(result as any)[key] = filteredArray

                if (filteredArray.length > 0) {
                    hasMatches = true
                }
            }
        },
    )

    return [result as T, hasMatches]
}

/**
 * SmartSearchAndFilter component provides advanced filtering
 * and search capabilities for an array of entities,
 * supporting both flat and nested object structures
 */
export default function SmartSearchAndFilter<T extends Record<string, any>>(
    defaultProps: SmartSearchAndFilterProps<T>,
) {
    const {
        entities,
        properties,
        handleSetFilteredEntities,
        propertyTransformers,
        labelTransformers,
        recursiveSearch = false,
    } = defaultProps

    // State for tracking filtered results and search/filter values
    const [filteredResults, setFilteredResults] = useState<T[] | null>(null)

    // Initialize filter states based on properties
    const [selectedFilters, setSelectedFilters] = useState<
        Record<string | number | symbol, string | null>
    >(
        properties
            ? Object.fromEntries(properties.map((prop) => [prop, null]))
            : {},
    )
    const [searchValue, setSearchValue] = useState('')

    // Memoized filtering logic combining property filters and search
    const filteredEntities = useMemo(() => {
        let filtered = entities

        // Apply dropdown filters for each property
        if (properties) {
            properties.forEach((property) => {
                const selectedValue = selectedFilters[property]
                if (selectedValue) {
                    filtered = filtered.filter((entity) => {
                        const entityValue = entity[property]?.toString()
                        if (
                            propertyTransformers &&
                            propertyTransformers[property]
                        ) {
                            return (
                                propertyTransformers[property]?.(
                                    entityValue,
                                ) === selectedValue
                            )
                        }

                        return entityValue === selectedValue
                    })
                }
            })
        }

        // Apply search filter if search value exists
        if (searchValue) {
            const lowercasedSearchValue = searchValue.toLowerCase()
            let recursiveResults: T[] = []

            // Handle recursive search if enabled
            if (recursiveSearch) {
                recursiveResults = filtered
                    .map((entity) => {
                        if (
                            !searchNestedObject(
                                entity,
                                lowercasedSearchValue,
                                propertyTransformers as Record<
                                    string,
                                    TransformerFunction
                                >,
                            )
                        ) {
                            return null
                        }

                        const [processedEntity, hasMatches] =
                            filterArrayProperties(
                                entity,
                                lowercasedSearchValue,
                                propertyTransformers as Record<
                                    string,
                                    TransformerFunction
                                >,
                            )

                        return hasMatches ? processedEntity : null
                    })
                    .filter((entity): entity is T => entity !== null)
            }

            // Apply standard search on direct properties
            const originalResults = filtered.filter((entity) => {
                return Object.entries(entity).some(([key, value]) => {
                    if (!value || ignoredProperties.includes(key)) return false
                    let searchableValue = value.toString()

                    if (
                        propertyTransformers &&
                        propertyTransformers[key as keyof T]
                    ) {
                        searchableValue =
                            propertyTransformers[key as keyof T]?.(value)
                    }

                    return searchableValue
                        .toLowerCase()
                        .includes(lowercasedSearchValue)
                })
            })

            // Combine and deduplicate results
            const uniqueMap = new Map()

            recursiveResults.forEach((entity) => {
                if (entity.id) {
                    uniqueMap.set(entity.id, entity)
                }
            })

            originalResults.forEach((entity) => {
                if (entity.id) {
                    uniqueMap.set(entity.id, entity)
                }
            })
            filtered = Array.from(uniqueMap.values())
        }

        return filtered
    }, [
        entities,
        properties,
        selectedFilters,
        searchValue,
        propertyTransformers,
        recursiveSearch,
    ])

    // Reset all filters and search
    const clearFilters = useCallback(() => {
        setSearchValue('')
        if (!properties) return

        setSelectedFilters(
            Object.fromEntries(properties.map((prop) => [prop, null])),
        )
    }, [properties])

    // Check if any filters are currently active
    const isFilterActive = useMemo(() => {
        return (
            Object.values(selectedFilters).some((value) => value !== null) ||
            !!searchValue
        )
    }, [selectedFilters, searchValue])

    // Update filtered results only when they change
    useEffect(() => {
        setFilteredResults((prevResults) => {
            if (
                JSON.stringify(prevResults) !== JSON.stringify(filteredEntities)
            ) {
                return filteredEntities
            }

            return prevResults
        })
    }, [filteredEntities])

    // Notify parent component of filtered results changes
    useEffect(() => {
        if (filteredResults !== null) {
            handleSetFilteredEntities(filteredResults)
        }
    }, [filteredResults, handleSetFilteredEntities])

    // Handle filter dropdown selection
    const handleSetFilter = useCallback(
        (property: keyof T, selectedOption: string | unknown) => {
            setSelectedFilters((prev) => ({
                ...prev,
                [property]: selectedOption ? String(selectedOption) : null,
            }))
        },
        [],
    )

    // Render individual filter dropdowns
    const renderFilterDropdown = useCallback(
        (property: keyof T, index: number) => {
            if (!entities) return null

            const transformer = propertyTransformers
                ? propertyTransformers[property]
                : null

            // Properties that should not be formatted
            const excludeFromFormat = ['currency', 'customer', 'name']
            // Boolean to check if property should be excluded from formatting
            const excludeFormat = excludeFromFormat.includes(property as string)

            // Get unique values for dropdown options
            const uniqueEntities = Array.from(
                new Set(
                    entities
                        .map((entity) => {
                            const value = entity[property]?.toString()
                            if (!value) {
                                return null
                            }

                            if (transformer) {
                                return transformer(value)
                            }

                            return value
                        })
                        .filter((value) => value && value.trim() !== ''),
                ),
            ).sort((a, b) => (a || '').includes(b || ''))

            // Create dropdown options
            const options = [
                { value: '', label: '--- Clear filter ---' },
                ...uniqueEntities.map((prop) => ({
                    value: prop,
                    label: capitalizeAndFormat(prop || '', excludeFormat),
                })),
            ]

            const selectedOption = options.find(
                (option) => option.value === selectedFilters[property],
            )

            // Transform label if labelTansformer(s) exists
            let basicPlaceholder = property.toString()
            if (labelTransformers && labelTransformers[basicPlaceholder]) {
                const transformedPlaceholder =
                    labelTransformers[basicPlaceholder]?.(property) || ''
                basicPlaceholder = transformedPlaceholder
            }
            const placeHolder = capitalizeAndFormat(basicPlaceholder)

            const label =
                index === 0 ? `Filter by: ${placeHolder}` : placeHolder

            return (
                <FilterWrapper>
                    <FormLabel>{label}</FormLabel>
                    <SelectDropdown
                        key={String(property)}
                        options={options}
                        value={selectedOption || null}
                        onChange={(selected) =>
                            handleSetFilter(property, selected)
                        }
                        isSearchable
                    />
                </FilterWrapper>
            )
        },
        [
            entities,
            propertyTransformers,
            labelTransformers,
            selectedFilters,
            handleSetFilter,
        ],
    )

    return (
        <TopRowContainer>
            <FilterAndSearchWrapper>
                {properties &&
                    properties.map((property, index) => (
                        <React.Fragment key={String(property)}>
                            {renderFilterDropdown(property, index)}
                        </React.Fragment>
                    ))}
                <SearchBarWrapper>
                    <i className="fa-regular fa-magnifying-glass icon" />
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                </SearchBarWrapper>
            </FilterAndSearchWrapper>
            {isFilterActive && (
                <TextButton onClick={clearFilters} text="Clear all filters" />
            )}
        </TopRowContainer>
    )
}
