import React, { useEffect, useState } from 'react'

import { getArticles } from 'api/articles'
import { getCustomers } from 'api/customers'
import { getMarginStructures } from 'api/marginStructures'
import { getPartners } from 'api/partners'
import { getCustomersSubscriptions } from 'api/vendors'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import { LoadingSpinner } from 'apps/shared/components/LoadingSpinner'
import NewItemButton from 'apps/shared/components/NewItemButton'
import { SmartSearchAndFilter } from 'apps/shared/components/SearchAndFilter'
import { TransformerFunction } from 'apps/shared/components/SearchAndFilter/SmartSearchAndFilter'
import CustomerSubscriptionInfo from 'apps/vendor/components/CustomerSubscriptionsInfo'
import LicenseSidebar from 'apps/vendor/components/Sidebars/LicenseSidebar'
import { CustomerSubscriptionsTable } from 'apps/vendor/components/Tables'
import {
    CustomerSubscriptionDataType,
    CustomerType,
    LicenseType,
    MarginStructureType,
    PartnerType,
    ArticleType,
} from 'apps/vendor/interfaces/subscriptions'
import useVendor from 'hooks/useVendor'

import { AccordionWrapper, BadgeRow, HeaderRow } from '../pages.styled'

export default function Subscriptions() {
    const [customersSubscriptionsData, setCustomersSubscriptionsData] =
        useState<CustomerSubscriptionDataType[] | []>([])

    const [
        filteredCustomersSubscriptionsData,
        setFilteredCustomersSubscriptionsData,
    ] = useState<CustomerSubscriptionDataType[] | []>([])
    const [partners, setPartners] = useState<PartnerType[] | []>([])

    const [marginStructures, setMarginStructures] = useState<
        MarginStructureType[] | []
    >([])
    const [customers, setCustomers] = useState<CustomerType[] | []>([])
    const [articles, setArticles] = useState<ArticleType[] | []>([])
    const [showLicenseModal, setShowLicenseModal] = useState(false)

    const [selectedLicense, setSelectedLicense] = useState<LicenseType | null>(
        null,
    )
    const [licenseCustomer, setLicenseCustomer] = useState<string | null>(null)
    const [loading, setLoading] = useState(true)
    const { vendor } = useVendor()

    useEffect(() => {
        setLoading(true)

        Promise.all([
            getMarginStructures(),
            getPartners(),
            getCustomers(),
            getArticles(),
            getCustomersSubscriptions(),
        ]).then(
            ([
                marginStructuresRes,
                partnersRes,
                customersRes,
                articlesRes,
                customerSubscriptionsRes,
            ]) => {
                setMarginStructures(marginStructuresRes.data)
                setPartners(partnersRes.data)
                setCustomers(customersRes.data)
                setArticles(articlesRes.data)
                setCustomersSubscriptionsData(customerSubscriptionsRes.data)
                setLoading(false)
            },
        )
    }, [])

    const updateCustomerSubscriptions = () => {
        getCustomersSubscriptions().then((resp) => {
            setCustomersSubscriptionsData(resp.data)
        })
    }

    const onLicenseModalSubmit = () => {
        updateCustomerSubscriptions()
        setSelectedLicense(null)
        setShowLicenseModal(false)
    }

    const handleSetSelectedLicense = (
        license: LicenseType,
        customer: string,
    ) => {
        setSelectedLicense(license)
        setLicenseCustomer(customer)
        setShowLicenseModal(!showLicenseModal)
    }

    const renderTable = () => {
        if (loading) return <LoadingSpinner />
        if (!filteredCustomersSubscriptionsData || loading) return null

        if (filteredCustomersSubscriptionsData.length === 0)
            return <EmptyListIcon />
        if (
            !partners ||
            !marginStructures ||
            !customers ||
            !articles ||
            !vendor
        )
            return null

        return (
            <>
                <HeaderRow
                    numberOfColumns={5}
                    rightJustifiedColumns={[2, 3, 4, 5]}
                    extraPaddingRight
                >
                    <span>Customer</span>
                    <span>ARR</span>
                    <span>Next Invoice</span>
                    <span>Invoice Value</span>
                    <span>Value Since Start</span>
                </HeaderRow>
                <AccordionWrapper alwaysOpen>
                    {filteredCustomersSubscriptionsData?.map(
                        (customerData, index) => {
                            return (
                                <AccordionItem
                                    key={customerData.id}
                                    eventKey={index.toString()}
                                    header={
                                        <BadgeRow
                                            numberOfColumns={5}
                                            rightJustifiedColumns={[2, 3, 4, 5]}
                                        >
                                            <CustomerSubscriptionInfo
                                                customerData={customerData}
                                            />
                                        </BadgeRow>
                                    }
                                    body={
                                        <CustomerSubscriptionsTable
                                            customerData={customerData}
                                            setSelectedLicense={
                                                handleSetSelectedLicense
                                            }
                                        />
                                    }
                                />
                            )
                        },
                    )}
                </AccordionWrapper>
            </>
        )
    }

    const changeCustomerLabel: TransformerFunction = (prop: string) => {
        return prop === 'name' ? 'customer' : prop
    }

    return (
        <div>
            {/* The `NewItemButton` is included for styling purposes */}
            {/* and does not have functionality without arguments.  */}
            <NewItemButton />
            {showLicenseModal && (
                <LicenseSidebar
                    onClose={() => {
                        setShowLicenseModal(!showLicenseModal)
                        setSelectedLicense(null)
                        setLicenseCustomer(null)
                    }}
                    onSubmit={() => onLicenseModalSubmit()}
                    articles={articles}
                    marginStructures={marginStructures}
                    partners={partners}
                    preselectedLicense={selectedLicense}
                    licenseCustomer={licenseCustomer}
                    customers={customers}
                    showSidebar={showLicenseModal}
                />
            )}
            <SmartSearchAndFilter
                entities={customersSubscriptionsData}
                properties={['name']}
                handleSetFilteredEntities={
                    setFilteredCustomersSubscriptionsData
                }
                labelTransformers={{
                    name: changeCustomerLabel,
                }}
                recursiveSearch
            />
            {renderTable()}
        </div>
    )
}
