import { API } from 'api'
import { LicenseNotificationAPIParams } from 'api/utils/interfaces'

export default function editLicenseNotificationConfig(
    licenseId: number,
    configId: number,
    params: LicenseNotificationAPIParams,
): Promise<any> {
    return API.put(`licenses/${licenseId}/notifications/${configId}/`, params)
}
