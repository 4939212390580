import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'

import { activateTwoFactor } from 'api/users'
import getTwoFactorQR from 'api/users/getTwoFactorQR'
import { OneTimeTokenField } from 'apps/shared/components/FormInputFields'
import { Modal } from 'apps/shared/components/Modals'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import { ModalWrapper } from 'apps/shared/components/Modals/Modal.styled'
import { TwoFactorAuthForm } from 'apps/vendor/components/Modals/TwoFactorModals/ActivateAuth.styled'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'

type ActivateTwoFactorModalProps = {
    token: string
    onClose: () => void
}

export default function ActivateTwoFactorModal(
    defaultProps: ActivateTwoFactorModalProps,
) {
    const { token, onClose } = defaultProps
    const { successToast, errorToast } = useToast()
    const { refetchUser } = useUser()
    const [qrCodeSvg, setQrCodeSvg] = useState('')
    const [totp, setTotp] = useState('')

    if (!token) {
        errorToast('Invalid token')
        onClose()
    }

    useEffect(() => {
        if (!qrCodeSvg) {
            getTwoFactorQR(token || '')
                .then((response) => {
                    if (response.status === 200 && response.data.qr_code) {
                        setQrCodeSvg(response.data.qr_code)
                    }
                })
                .catch(() => {
                    errorToast('Failed to get QR code')
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, qrCodeSvg])

    const handleSubmit = async () => {
        activateTwoFactor({
            totp_code: totp,
            token: token || '',
        })
            .then((response) => {
                if (response.status === 200) {
                    successToast('Two-factor authentication enabled')
                    refetchUser()
                    onClose()
                }
            })
            .catch(() => {
                errorToast('Invalid code')
                setTotp('')
            })
    }

    const handleTokenChange = (e: string) => {
        setTotp(e)
    }

    const renderTwoFactorAuthForm = () => {
        return (
            <ModalWrapper>
                <TwoFactorAuthForm>
                    <Form
                        onSubmit={handleSubmit}
                        onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
                    >
                        <div className="login-component">
                            <div className="card-body p-5 text-center">
                                {qrCodeSvg && (
                                    <div
                                        // eslint-disable-next-line max-len
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: qrCodeSvg,
                                        }}
                                    />
                                )}
                            </div>
                            <p className="textInfo">
                                Enter code from Authenticator app
                            </p>
                            <OneTimeTokenField onChange={handleTokenChange} />
                        </div>
                    </Form>
                </TwoFactorAuthForm>
            </ModalWrapper>
        )
    }

    return (
        <Modal
            onClose={onClose}
            title="Scan QR code with Authentication app"
            body={renderTwoFactorAuthForm()}
            footer={
                <CancelSubmitFooter
                    customSubmitText="Submit"
                    onClose={onClose}
                    onSubmit={() => handleSubmit()}
                    canSubmit={totp.length === 6}
                />
            }
            size="lg"
        />
    )
}
