import { Dropdown } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

const SIZES = {
    USER_ICON_SIZE: '42px',
    PAGE_HEADER_SIZE: '66px',
} as const

export const PageHeadWrapper = styled.header`
    display: grid;
    grid-template-columns: 220px 1fr;
    text-align: center;
    width: 100%;
    align-items: center;
    background-color: ${primary.darkBlue};

    @media screen and (max-width: 1200px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
`

export const LogoSection = styled.div`
    display: flex;
    align-items: center;
    height: ${SIZES.PAGE_HEADER_SIZE};
    width: 100%;
    justify-content: start;
    margin-left: 10px;

    @media screen and (max-width: 750px) {
        display: none;
    }

    @media screen and (max-width: 1200px) {
        margin-left: 40px;
    }

    > * {
        width: 180px;
    }
`

export const BadgeContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    align-items: center;
    height: 66px !important;

    > *:not(.settings-dropdown) {
        pointer-events: none;
    }
    @media screen and (max-width: 1200px) {
        justify-content: center;
    }
`

export const SettingsDropdown = styled(Dropdown)`
    display: inline-block;
    pointer-events: auto;

    .dropdown-toggle {
        background: none;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 1em;

        &::after {
            display: none;
        }

        &:focus,
        &:active,
        &:hover {
            background: none !important;
            border: none !important;
            box-shadow: none !important;
        }
    }

    .dropdown-menu {
        background-color: ${secondary.almostWhiteGray};
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        width: 100%;
        margin-top: 0.5rem;
        pointer-events: auto;

        &.show {
            transform: translate(0px, 57px) !important;
        }

        .dropdown-item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            color: ${primary.darkGray};
            pointer-events: auto;
            cursor: pointer;

            > div {
                width: 25px;
            }

            &:active,
            &:hover {
                background-color: ${secondary.lightGray};
            }

            .profile {
                color: ${primary.blue};
            }

            .sign-out {
                color: ${primary.red};
            }
        }
    }
`

export const DisplayIconContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1em;
    pointer-events: auto;

    &:hover {
        .display-icon {
            background-color: ${secondary.lavender};
        }

        .display-name {
            color: white;
            text-decoration: underline;
        }
    }
`

export const CircleIcon = styled.div`
    height: ${SIZES.USER_ICON_SIZE};
    width: ${SIZES.USER_ICON_SIZE};
    min-width: ${SIZES.USER_ICON_SIZE};
    min-height: ${SIZES.USER_ICON_SIZE};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${secondary.lightLavender};
    color: ${primary.darkBlue};
    border-radius: 50%;
    font-size: 1.2em;
    font-weight: 300;
    transition: background-color 0.3s linear;

    @media screen and (max-width: 575px) {
        font-size: 1em;
        font-weight: 200;
    }
`

export const DisplayName = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1em;
    color: ${secondary.lightGray};
    font-weight: 200;
    max-width: 250px;
    min-width: 100px;
`
