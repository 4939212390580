import styled from 'styled-components'
import { primary } from 'utils/colors'

export const DownloadPdfButtonWrapper = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 0;
    color: ${primary.blue};
    gap: 10px;

    &:hover {
        text-decoration: underline;
        color: ${primary.lightBlue};
    }
`
