import React, { useEffect, useRef } from 'react'

import {
    SaveButton,
    CancelButton,
    KebabButton,
} from 'apps/shared/components/Buttons'
import MultipleEmailInput from 'apps/shared/components/FormInputFields/EmailInput/MultipleEmailInput'
import { Checkbox } from 'apps/shared/components/Modals/Modal.styled'
import { FormLabel } from 'apps/shared/shared.styled'
import { LicenseNotification } from 'apps/vendor/interfaces/subscriptions'

import {
    ConfigCard,
    ConfigCheckboxSection,
} from './NotificationConfigCard.styled'

interface NotificationConfigCardProps {
    emails: string[]
    notificationOptions: Array<{
        type: LicenseNotification
        leadDays: number | null
    }>
    isManualLicense: boolean
    disabled?: boolean
    isEditing?: boolean
    canSubmit?: boolean
    onEmailsChange?: (emails: string[]) => void
    onNotificationOptionChange?: (
        type: LicenseNotification,
        leadDays: number | null,
    ) => void
    onEdit?: () => void
    onDelete?: () => void
    onSubmit?: () => void
    onCancel?: () => void
}

export default function NotificationConfigCard(
    defaultProps: NotificationConfigCardProps,
) {
    const {
        emails,
        notificationOptions,
        isManualLicense,
        disabled = false,
        isEditing,
        canSubmit,
        onEmailsChange,
        onNotificationOptionChange,
        onEdit,
        onDelete,
        onSubmit,
        onCancel,
    } = defaultProps

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const renderNotificationCheckbox = (
        label: string,
        type: LicenseNotification,
        value: number | null,
    ) => (
        <Checkbox
            key={`license-notification-${type}-${value}`}
            id={`license-notification-${type}-${value}`}
            name={`license-notification-${type}-${value}`}
            label={label}
            checked={notificationOptions.some(
                (option) => option.type === type && option.leadDays === value,
            )}
            disabled={!isEditing}
            onChange={() => onNotificationOptionChange?.(type, value)}
        />
    )

    const renderNotificationCheckboxes = () => {
        const reminderDates = [7, 14, 30, 60, 90]

        const notificationOn = [
            ['Renewed', LicenseNotification.ON_RENEWAL],
            ['Invoiced', LicenseNotification.ON_INVOICING],
            ['Paid', LicenseNotification.ON_PAID],
            ['Overdue', LicenseNotification.OVERDUE],
        ]

        return (
            <ConfigCheckboxSection>
                <FormLabel>
                    Notify this many days before expiration date
                </FormLabel>
                <section>
                    {reminderDates.map((days) =>
                        renderNotificationCheckbox(
                            days.toString(),
                            LicenseNotification.REMINDER,
                            days,
                        ),
                    )}
                </section>
                {!isManualLicense && (
                    <>
                        <FormLabel>Notify when license is</FormLabel>
                        <section>
                            {notificationOn.map(([label, type]) =>
                                renderNotificationCheckbox(
                                    label as string,
                                    type as LicenseNotification,
                                    null,
                                ),
                            )}
                        </section>
                    </>
                )}
            </ConfigCheckboxSection>
        )
    }

    const renderSaveCancelButtons = () => {
        if (isEditing && onSubmit && onCancel) {
            return (
                <div className="card-footer">
                    <CancelButton onClick={onCancel} />
                    <SaveButton onClick={onSubmit} canSubmit={canSubmit} />
                </div>
            )
        }

        return null
    }

    const getClassName = () => {
        if (disabled) {
            return 'disabled'
        }

        if (isEditing) {
            return 'editing'
        }

        return ''
    }

    return (
        <ConfigCard className={getClassName()}>
            <div className="card-header">
                <FormLabel>Notification emails</FormLabel>
                {!isEditing && onEdit && onDelete && (
                    <KebabButton onEdit={onEdit} onDelete={onDelete} />
                )}
            </div>
            {isEditing && onEmailsChange ? (
                <MultipleEmailInput
                    inputRef={inputRef}
                    value={emails}
                    placeholder="Add email to receive notifications"
                    onChange={onEmailsChange}
                />
            ) : (
                <div className="notification-info-emails">
                    {emails.map((email) => (
                        <p key={email}>{email}</p>
                    ))}
                </div>
            )}
            <section className="notification-options">
                {renderNotificationCheckboxes()}
                {renderSaveCancelButtons()}
            </section>
        </ConfigCard>
    )
}
