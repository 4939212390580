import { Modal, Form } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

interface MultipleSectionProps {
    fractions?: string[]
}

export const TitleWrapper = styled(Modal.Title)`
    span {
        color: ${primary.gray};
        font-size: 0.9rem;
        border-left: 1px solid ${primary.darkGray};
        margin-left: 10px;
        padding-left: 10px;
    }
`

export const MultipleSection = styled.section<MultipleSectionProps>`
    > div > * {
        margin-bottom: 0.5rem;
    }

    @media screen and (min-width: 991px) {
        display: grid;
        grid-template-columns: ${({ fractions }) =>
            fractions?.join(' ') || '1fr 1fr'};
        gap: 1rem;
        margin-bottom: 0;
    }
`

export const ModalWrapper = styled.div<{ height?: string }>`
    max-height: ${({ height }) => height || '80vh'};

    .scrollable-content {
        max-height: inherit;
        overflow: scroll;
        scrollbar-gutter: stable;
        -webkit-overflow-scrolling: auto;

        /* For Webkit browsers (Chrome, Safari) */
        &::-webkit-scrollbar {
            width: 0.4rem;
        }

        &::-webkit-scrollbar-thumb {
            background: ${secondary.lightGray};
            border-radius: 4px;
        }

        > * {
            margin-bottom: 0.5rem;
        }
    }
`

export const Checkbox = styled(Form.Check)`
    > label {
        font-size: 0.9rem;
    }
    * {
        cursor: pointer;
    }
`

export const RemoveListItemButton = styled.i`
    color: ${primary.darkGray};
    display: flex;
    align-items: center;
`

export const MissingAssetText = styled.p`
    color: ${primary.red};
    font-style: italic;
`
