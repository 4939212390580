import { Accordion } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

interface ColumnProps {
    numberOfColumns: number
    rightJustifiedColumns?: number[]
    extraPaddingRight?: boolean
}

interface EmptyListProps {
    insideModal?: boolean
}

export const HeaderRow = styled.div<ColumnProps>`
    padding: 20px;
    ${({ extraPaddingRight }) =>
        extraPaddingRight ? 'padding-right: 40px;' : ''}
    color: ${primary.gray};
    font-size: 0.8rem;
    margin-bottom: -0.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: ${({ numberOfColumns }) =>
        `repeat(${numberOfColumns}, 1fr)`};

    ${({ rightJustifiedColumns }) =>
        rightJustifiedColumns &&
        rightJustifiedColumns.map(
            (n: number) => `
            > :nth-child(${n}) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                text-align: right;
            }
        `,
        )}

    .centered {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;
    }
`

export const BadgeRow = styled.div<ColumnProps>`
    width: 100%;
    display: grid;
    grid-template-columns: ${({ numberOfColumns }) =>
        `repeat(${numberOfColumns}, 1fr)`};
    > a {
        text-decoration: none;
        :hover {
            text-decoration: underline;
        }
    }

    ${({ rightJustifiedColumns }) =>
        rightJustifiedColumns &&
        rightJustifiedColumns.map(
            (n: number) => `
                > :nth-child(${n}) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    text-align: right;
                }
            `,
        )}

    .centered {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;

        > span {
            place-self: center;
        }
    }

    @media screen and (max-width: 1620px) {
        gap: 10px;
        > span,
        > a {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        > div {
            :last-child {
                width: 24px;
                justify-self: end;
            }
        }
    }
`

export const AccordionBody = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    color: ${primary.darkBlue};

    justify-content: space-between;
    > *:not(.kebab) {
        > :first-child {
            color: ${primary.gray};
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
        }

        > .centered {
            text-align: center;
        }
    }
    > :first-child {
        text-align: left;
    }
    > [role='button'] {
        align-self: center;
        font-size: 1.5rem;
    }

    > * {
        display: flex;
        flex-direction: column;
    }
`

export const AccordionWrapper = styled(Accordion)`
    overflow: auto;
    height: 75vh;
`

export const EmptyListIconWrapper = styled.p<EmptyListProps>`
    font-size: 5rem;
    text-align: center;
    color: ${secondary.lightGray};

    ${({ insideModal }) =>
        insideModal && `font-size: 2rem; margin: 0; padding: 0;`}
`

export const PageLine = styled.div`
    border-bottom: 2px solid ${secondary.whisperWhite};
    width: 100%;
    margin-bottom: 1rem;
`
export const NoLicensesInfoIconWrapper = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;

    > p {
        text-align: center;
        color: ${primary.blue};
        font-size: 1.5rem;
    }
`

export const RowButtonWrapper = styled.div`
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    position: relative;
`

export const ClickBlocker = styled.div`
    position: absolute;
    top: 50%;
    right: -20px;
    width: 80px;
    height: 52px;
    transform: translateY(-50%);
    cursor: default;
`
