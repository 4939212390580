import React, { useEffect, useState } from 'react'

import { deleteCustomer, getCustomers } from 'api/customers'
import AccordionItem from 'apps/shared/components/AccordionItem/AccordionItem'
import { DeleteButton } from 'apps/shared/components/Buttons'
import {
    EmptyListIcon,
    StatusIcon,
    StatusType,
} from 'apps/shared/components/Icons'
import { LoadingSpinner } from 'apps/shared/components/LoadingSpinner'
import { ConfirmModal } from 'apps/shared/components/Modals'
import { ConfirmModalBody } from 'apps/shared/components/Modals/ConfirmModal/ConfirmModal.styled'
import NewItemButton from 'apps/shared/components/NewItemButton'
import { SmartSearchAndFilter } from 'apps/shared/components/SearchAndFilter'
import { CustomerSidebar } from 'apps/vendor/components/Sidebars'
import {
    CustomerStatus,
    CustomerType,
} from 'apps/vendor/interfaces/subscriptions'
import {
    AccordionWrapper,
    BadgeRow,
    ClickBlocker,
    HeaderRow,
    RowButtonWrapper,
} from 'apps/vendor/pages/pages.styled'
import useToast from 'hooks/useToast'

export default function CustomersPage() {
    const [customers, setCustomers] = useState<CustomerType[] | []>([])

    const [filteredCustomers, setFilteredCustomers] = useState<CustomerType[]>(
        [],
    )
    const [showCustomerSidebar, setShowCustomerSidebar] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [loading, setLoading] = useState(true)

    const [selectedCustomer, setSelectedCustomer] =
        useState<CustomerType | null>(null)

    const { successToast, errorToast } = useToast()

    const updateCustomersList = () => {
        getCustomers().then((resp) => {
            setCustomers(resp.data)
            setLoading(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        updateCustomersList()
    }, [])

    const handleEditCustomer = (customer: CustomerType) => {
        setSelectedCustomer(customer)
        setShowCustomerSidebar(!showCustomerSidebar)
    }

    const handleToggleDeleteModal = (customer: CustomerType) => {
        setSelectedCustomer(customer)
        setShowDeleteModal(!showDeleteModal)
    }

    const onCustomerModalSubmit = () => {
        updateCustomersList()
        setSelectedCustomer(null)
        setShowCustomerSidebar(false)
    }

    const getAccordionHeaders = (customer: CustomerType) => {
        return (
            <BadgeRow numberOfColumns={5}>
                <span>{customer.name}</span>
                <span>{customer.reference || '-'}</span>
                <a
                    href={`mailto:${customer.contact_email}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    {customer.contact_email}
                </a>
                <span className="centered">
                    <StatusIcon
                        type={
                            customer.status === CustomerStatus.COMPLETE
                                ? StatusType.SUCCESS
                                : StatusType.WARNING
                        }
                        text={customer.status}
                    />
                </span>
                <RowButtonWrapper>
                    <ClickBlocker
                        onClick={(event) => event.stopPropagation()}
                    />
                    <DeleteButton
                        onClick={(event) => {
                            event.stopPropagation()
                            handleToggleDeleteModal(customer)
                        }}
                    />
                </RowButtonWrapper>
            </BadgeRow>
        )
    }

    const renderTable = () => {
        if (loading) return <LoadingSpinner />

        if (filteredCustomers.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                <HeaderRow numberOfColumns={5}>
                    <span>Name</span>
                    <span>Reference</span>
                    <span>Email</span>
                    <span className="centered">Status</span>
                </HeaderRow>
                <AccordionWrapper>
                    {filteredCustomers.map((customer, index) => {
                        return (
                            <AccordionItem
                                key={customer.id}
                                eventKey={index.toString()}
                                header={getAccordionHeaders(customer)}
                                onClick={() => {
                                    handleEditCustomer(customer)
                                }}
                            />
                        )
                    })}
                </AccordionWrapper>
            </>
        )
    }

    const handleDeleteCustomer = () => {
        if (selectedCustomer) {
            deleteCustomer(selectedCustomer.id)
                .then(() => {
                    setSelectedCustomer(null)
                    updateCustomersList()
                    setShowDeleteModal(!showDeleteModal)
                    successToast('Customer deleted successfully')
                })
                .catch(() => {
                    return errorToast(
                        // eslint-disable-next-line max-len
                        'Unknown error. Please try again and contact Sharlic support if error persists',
                    )
                })
        }
    }

    const renderDeletionConstraintsWarning = () => {
        return (
            <section>
                <p>
                    This customer cannot be deleted because it is currently in
                    use by the following licenses:
                </p>
                <ul>
                    {selectedCustomer?.related_licenses?.map((license) => (
                        <li key={license.name}>
                            <b>{license.name}</b>
                        </li>
                    ))}
                </ul>
            </section>
        )
    }

    const renderConfirmDeleteSection = () => {
        return (
            <>
                <section>
                    You are about to delete the following customer:
                    <p>
                        <b>{selectedCustomer?.name}</b>
                    </p>
                </section>
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </>
        )
    }

    const customerDeletable = () => {
        return selectedCustomer?.related_licenses?.length === 0
    }

    const renderConfirmModalBody = () => {
        let html = renderConfirmDeleteSection()

        if (!customerDeletable()) {
            html = renderDeletionConstraintsWarning()
        }

        return <ConfirmModalBody>{html}</ConfirmModalBody>
    }

    return (
        <div>
            <NewItemButton
                onButtonClicked={() =>
                    setShowCustomerSidebar(!showCustomerSidebar)
                }
            />
            {showCustomerSidebar && (
                <CustomerSidebar
                    onClose={() => {
                        setShowCustomerSidebar(!showCustomerSidebar)
                        setSelectedCustomer(null)
                    }}
                    onSubmit={() => onCustomerModalSubmit()}
                    preselectedCustomer={selectedCustomer}
                    customers={customers || []}
                    showSidebar={showCustomerSidebar}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedCustomer(null)
                    }}
                    onSubmit={() => handleDeleteCustomer()}
                    customSubmitText="Delete"
                    body={renderConfirmModalBody()}
                    deleteButton
                    canSubmit={customerDeletable()}
                />
            )}
            <SmartSearchAndFilter
                entities={customers}
                properties={['status']}
                handleSetFilteredEntities={setFilteredCustomers}
            />
            {renderTable()}
        </div>
    )
}
