import React from 'react'

import { DeclineButtonWrapper } from './DeclineButton.styled'

export default function DeclineButton(props: { onClick: () => void }) {
    const { onClick } = props

    return (
        <DeclineButtonWrapper
            className="fa-solid fa-circle-xmark"
            onClick={() => onClick()}
        />
    )
}
