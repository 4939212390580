import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const TopRowContainer = styled.div`
    height: auto;
    min-height: 90px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 16px;

    @media screen and (max-width: 750px) {
        margin-bottom: 24px;
    }
`

export const FilterAndSearchWrapper = styled.div`
    display: flex;
    justify-content: start;
    gap: 20px;
    margin-bottom: 8px;
    margin-top: -5px;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;

    > div {
        min-width: 200px;
        max-width: 400px;
        width: 100%;

        @media screen and (max-width: 750px) {
            min-width: 100%;
            max-width: 100%;
            margin-bottom: 12px;
        }
    }

    @media screen and (max-width: 990px) {
        gap: 2px;
    }

    @media screen and (max-width: 750px) {
        margin-bottom: 16px;
    }
`

export const SearchBarWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 36px !important;
    margin-top: 29.5px;

    @media screen and (max-width: 750px) {
        max-width: 100%;
        margin-top: 16px;
        margin-bottom: 8px;
    }

    input {
        width: 100%;
        padding-left: 30px;
        border: 1px solid ${secondary.lightGray};
        border-radius: 4px;
        height: 38px;
        box-sizing: border-box;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        color: ${primary.darkGray};
    }
`

export const FilterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 750px) {
        margin-bottom: 8px;
    }
`
