import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const AddConfigurationButton = styled.div<{ disabled?: boolean }>`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: fit-content;

    :hover {
        cursor: pointer;
        color: ${primary.darkGray};
    }

    :active {
        color: ${secondary.lightGray};
    }

    ${({ disabled }) =>
        disabled &&
        `
        pointer-events: none;
        color: ${primary.lightGray};
    `}
`
