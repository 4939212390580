import React, { useEffect, useState } from 'react'

import { getMarginStructures } from 'api/marginStructures'
import deleteMarginStructure from 'api/marginStructures/deleteMarginStructure'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { DeleteButton } from 'apps/shared/components/Buttons'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import { LoadingSpinner } from 'apps/shared/components/LoadingSpinner'
import { ConfirmModal } from 'apps/shared/components/Modals'
import { ConfirmModalBody } from 'apps/shared/components/Modals/ConfirmModal/ConfirmModal.styled'
import NewItemButton from 'apps/shared/components/NewItemButton'
import { SmartSearchAndFilter } from 'apps/shared/components/SearchAndFilter'
import { MarginStructureSidebar } from 'apps/vendor/components/Sidebars'
import { MarginStructureType } from 'apps/vendor/interfaces/subscriptions'
import {
    AccordionWrapper,
    BadgeRow,
    ClickBlocker,
    HeaderRow,
    RowButtonWrapper,
} from 'apps/vendor/pages/pages.styled'
import useToast from 'hooks/useToast'
import useVendor from 'hooks/useVendor'

export type VendorType = {
    name: string
    contact_email: string
    stripe_account_linked: boolean
}

export default function MarginStructuresPage() {
    const [marginStructures, setMarginStructures] = useState<
        MarginStructureType[] | []
    >([])

    const [filteredMarginStructures, setFilteredMarginStructures] = useState<
        MarginStructureType[] | []
    >([])
    const { vendor } = useVendor()

    const [showMarginStructureSidebar, setShowMarginStructureSidebar] =
        useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [selectedMarginStructure, setSelectedMarginStructure] =
        useState<MarginStructureType | null>(null)

    const [loading, setLoading] = useState(true)
    const { successToast, errorToast } = useToast()

    const updateMarginStructureList = () => {
        getMarginStructures().then((response) => {
            setMarginStructures(response.data)
            setLoading(false)
        })
        setSelectedMarginStructure(null)
        setShowMarginStructureSidebar(false)
    }

    useEffect(() => {
        setLoading(true)
        updateMarginStructureList()
    }, [])

    const getNumberOfSalesTiers = () => {
        if (!marginStructures) return 0

        let numberOfSalesTiers = 0

        marginStructures.forEach((margin) => {
            numberOfSalesTiers =
                margin.number_of_sales_tiers > numberOfSalesTiers
                    ? margin.number_of_sales_tiers
                    : numberOfSalesTiers
        })

        return numberOfSalesTiers
    }

    const numberOfSalesTiers = getNumberOfSalesTiers()

    const getHeaders = () => {
        return (
            <HeaderRow
                numberOfColumns={numberOfSalesTiers + 2}
                rightJustifiedColumns={[2, 3, 4]}
            >
                <span>Name</span>
                <span>{vendor && vendor.name}</span>
                {numberOfSalesTiers > 2 && <span>Distributor</span>}
                {numberOfSalesTiers > 1 && <span>Reseller</span>}
            </HeaderRow>
        )
    }

    const handleEditMarginStructure = (
        marginStructure: MarginStructureType,
    ) => {
        setSelectedMarginStructure(marginStructure)
        setShowMarginStructureSidebar(!showMarginStructureSidebar)
    }

    const handleToggleDeleteModal = (marginStructure: MarginStructureType) => {
        setSelectedMarginStructure(marginStructure)
        setShowDeleteModal(!showDeleteModal)
    }

    const getAccordionHeaders = (
        marginStructure: MarginStructureType,
        percentagePerTier: string[],
    ) => {
        return (
            <BadgeRow
                numberOfColumns={numberOfSalesTiers + 2}
                rightJustifiedColumns={[2, 3, 4]}
            >
                <span>{marginStructure.name}</span>
                <span>{percentagePerTier[0]}%</span>
                {numberOfSalesTiers > 2 && (
                    <span>
                        {percentagePerTier[1] && percentagePerTier.length > 2
                            ? `${percentagePerTier[1]}%`
                            : '-'}
                    </span>
                )}
                {numberOfSalesTiers > 1 && (
                    <span>
                        {(percentagePerTier[2] || percentagePerTier[1])?.concat(
                            '%',
                        ) || '-'}
                    </span>
                )}
                <RowButtonWrapper>
                    <ClickBlocker
                        onClick={(event) => event.stopPropagation()}
                    />
                    <DeleteButton
                        onClick={(event) => {
                            event.stopPropagation()
                            handleToggleDeleteModal(marginStructure)
                        }}
                    />
                </RowButtonWrapper>
            </BadgeRow>
        )
    }

    const renderTable = () => {
        if (loading) return <LoadingSpinner />

        if (filteredMarginStructures.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                {getHeaders()}
                <AccordionWrapper>
                    {filteredMarginStructures.map((marginStructure, index) => {
                        const percentagePerTier =
                            marginStructure.percentage_per_tier.split(',')

                        return (
                            <AccordionItem
                                key={marginStructure.id}
                                eventKey={index.toString()}
                                header={getAccordionHeaders(
                                    marginStructure,
                                    percentagePerTier,
                                )}
                                onClick={() =>
                                    handleEditMarginStructure(marginStructure)
                                }
                            />
                        )
                    })}
                </AccordionWrapper>
            </>
        )
    }

    const handleDeleteMarginStructure = () => {
        if (selectedMarginStructure) {
            deleteMarginStructure(selectedMarginStructure.id)
                .then(() => {
                    updateMarginStructureList()
                    setSelectedMarginStructure(null)
                    setShowDeleteModal(!showDeleteModal)
                    successToast('Margin structure deleted successfully')
                })
                .catch(() => {
                    return errorToast(
                        // eslint-disable-next-line max-len
                        'Unknown error. Please try again and contact Sharlic support if error persists',
                    )
                })
        }
    }

    const renderDeletionConstraintsWarning = () => {
        return (
            <section>
                <p>
                    This margin structure cannot be deleted because it is
                    currently in use by the following licenses:
                </p>
                <ul>
                    {selectedMarginStructure?.related_licenses?.map(
                        (license) => (
                            <li key={license.name}>
                                <b>{license.name}</b> owned by{' '}
                                <b>{license.vendor}</b>
                            </li>
                        ),
                    )}
                </ul>
            </section>
        )
    }

    const renderConfirmDeleteSection = () => {
        return (
            <>
                <section>
                    You are about to to delete the following margin structure:
                    <p>
                        <b>{selectedMarginStructure?.name}</b>?
                    </p>
                </section>
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </>
        )
    }

    const marginStructureDeletable = () => {
        return selectedMarginStructure?.related_licenses?.length === 0
    }

    const renderConfirmModalBody = () => {
        let html = renderConfirmDeleteSection()

        if (!marginStructureDeletable()) {
            html = renderDeletionConstraintsWarning()
        }

        return <ConfirmModalBody>{html}</ConfirmModalBody>
    }

    return (
        <div>
            <NewItemButton
                onButtonClicked={() =>
                    setShowMarginStructureSidebar(!showMarginStructureSidebar)
                }
            />
            {showMarginStructureSidebar && (
                <MarginStructureSidebar
                    onClose={() => {
                        setShowMarginStructureSidebar(
                            !showMarginStructureSidebar,
                        )
                        setSelectedMarginStructure(null)
                    }}
                    onSubmit={() => updateMarginStructureList()}
                    preselectedMarginStructure={selectedMarginStructure}
                    showSidebar={showMarginStructureSidebar}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedMarginStructure(null)
                    }}
                    onSubmit={() => handleDeleteMarginStructure()}
                    customSubmitText="Delete"
                    body={renderConfirmModalBody()}
                    deleteButton
                    canSubmit={marginStructureDeletable()}
                />
            )}
            <SmartSearchAndFilter
                entities={marginStructures}
                properties={['number_of_sales_tiers', 'available_for_partners']}
                handleSetFilteredEntities={setFilteredMarginStructures}
            />
            {renderTable()}
        </div>
    )
}
