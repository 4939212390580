import React, { useEffect, useState } from 'react'

import { deleteArticle, getArticles } from 'api/articles'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { DeleteButton } from 'apps/shared/components/Buttons'
import TextButton from 'apps/shared/components/Buttons/TextButton'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import { LoadingSpinner } from 'apps/shared/components/LoadingSpinner'
import { ConfirmModal } from 'apps/shared/components/Modals'
import { ConfirmModalBody } from 'apps/shared/components/Modals/ConfirmModal/ConfirmModal.styled'
import NewItemButton from 'apps/shared/components/NewItemButton'
import { SmartSearchAndFilter } from 'apps/shared/components/SearchAndFilter'
import { ArticleSidebar } from 'apps/vendor/components/Sidebars'
import { ArticleType } from 'apps/vendor/interfaces/subscriptions'
import {
    AccordionWrapper,
    BadgeRow,
    ClickBlocker,
    HeaderRow,
    RowButtonWrapper,
} from 'apps/vendor/pages/pages.styled'
import { localeNumber } from 'apps/vendor/utils'
import { useUser } from 'hooks'
import useToast from 'hooks/useToast'

export default function ArticlesPage() {
    const [articles, setArticles] = useState<ArticleType[] | []>([])

    const [filteredArticles, setFilteredArticles] = useState<
        ArticleType[] | []
    >([])
    const [showArticleSidebar, setShowArticleSidebar] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [loading, setLoading] = useState(true)

    const [selectedArticle, setSelectedArticle] = useState<ArticleType | null>(
        null,
    )
    const { user } = useUser()
    const { successToast, errorToast } = useToast()

    const updateArticlesList = () => {
        setLoading(true)

        getArticles()
            .then((response) => {
                setArticles(response.data)
                setLoading(false)
            })
            .catch(() => {
                errorToast('Error fetching data')
                setLoading(false)
            })
    }

    useEffect(() => {
        updateArticlesList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onArticleModalSubmit = () => {
        updateArticlesList()
        setShowArticleSidebar(false)
        setSelectedArticle(null)
    }

    const handleEditArticle = (article: ArticleType) => {
        setSelectedArticle(article)
        setShowArticleSidebar(!showArticleSidebar)
    }

    const handleToggleDeleteModal = (article: ArticleType) => {
        setSelectedArticle(article)
        setShowDeleteModal(!showDeleteModal)
    }

    const handleDeleteArticle = () => {
        if (selectedArticle) {
            deleteArticle(selectedArticle.id)
                .then(() => {
                    updateArticlesList()
                    setSelectedArticle(null)
                    setShowDeleteModal(!showDeleteModal)
                    successToast('Article deleted successfully')
                })
                .catch(() => {
                    return errorToast(
                        // eslint-disable-next-line max-len
                        'Unknown error. Please try again and contact Sharlic support if error persists',
                    )
                })
        }
    }

    const renderTextButton = (article: ArticleType) => {
        return (
            <TextButton
                onClick={() => handleEditArticle(article)}
                text={article.name}
            />
        )
    }

    const getAccordionHeaders = (article: ArticleType) => {
        return (
            <BadgeRow numberOfColumns={5} rightJustifiedColumns={[2]}>
                {renderTextButton(article)}
                <span>
                    {localeNumber(Number(article.msrp), {
                        locale: user?.locale,
                    })}
                </span>
                <span>{article.currency}</span>
                <span>{article.external_id}</span>
                <RowButtonWrapper>
                    <ClickBlocker
                        onClick={(event) => event.stopPropagation()}
                    />
                    <DeleteButton
                        onClick={(event) => {
                            event.stopPropagation()
                            handleToggleDeleteModal(article)
                        }}
                    />
                </RowButtonWrapper>
            </BadgeRow>
        )
    }

    const renderTable = () => {
        if (loading) return <LoadingSpinner />

        if (filteredArticles.length === 0) {
            return <EmptyListIcon />
        }

        return (
            <>
                <HeaderRow numberOfColumns={5} rightJustifiedColumns={[2]}>
                    <span>Name</span>
                    <span>MSRP</span>
                    <span>Currency</span>
                    <span>Article number</span>
                </HeaderRow>
                <AccordionWrapper>
                    {filteredArticles.map((article, index) => (
                        <AccordionItem
                            key={article.external_id}
                            eventKey={index.toString()}
                            header={getAccordionHeaders(article)}
                            onClick={() => {
                                handleEditArticle(article)
                            }}
                        />
                    ))}
                </AccordionWrapper>
            </>
        )
    }

    const renderDeletionConstraintsWarning = () => {
        return (
            <section>
                <p>
                    This article cannot be deleted because it is currently in
                    use by the following licenses:
                </p>
                <ul>
                    {selectedArticle?.related_licenses?.map((license) => (
                        <li key={license.name}>
                            <b>{license.name}</b> owned by{' '}
                            <b>{license.vendor}</b>
                        </li>
                    ))}
                </ul>
            </section>
        )
    }

    const renderConfirmDeleteSection = () => {
        return (
            <>
                <section>
                    You are about to to delete the following article:
                    <p>
                        <b>{selectedArticle?.name}</b>?
                    </p>
                </section>
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </>
        )
    }

    const ArticleDeletable = () => {
        return selectedArticle?.related_licenses?.length === 0
    }

    const renderConfirmModalBody = () => {
        let html = renderConfirmDeleteSection()

        if (!ArticleDeletable()) {
            html = renderDeletionConstraintsWarning()
        }

        return <ConfirmModalBody>{html}</ConfirmModalBody>
    }

    return (
        <div>
            <NewItemButton
                onButtonClicked={() =>
                    setShowArticleSidebar(!showArticleSidebar)
                }
            />
            {showArticleSidebar && (
                <ArticleSidebar
                    onClose={() => {
                        setShowArticleSidebar(!showArticleSidebar)
                        setSelectedArticle(null)
                    }}
                    onSubmit={() => onArticleModalSubmit()}
                    preselectedArticle={selectedArticle}
                    showSidebar={showArticleSidebar}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedArticle(null)
                    }}
                    onSubmit={() => handleDeleteArticle()}
                    customSubmitText="Delete"
                    body={renderConfirmModalBody()}
                    deleteButton
                    canSubmit={ArticleDeletable()}
                />
            )}
            <SmartSearchAndFilter
                entities={articles}
                properties={['currency', 'available_for_partners']}
                handleSetFilteredEntities={setFilteredArticles}
            />
            {renderTable()}
        </div>
    )
}
