import React, { useEffect, useState } from 'react'

import { getArticles } from 'api/articles'
import { getCustomers } from 'api/customers'
import { deleteLicense, getLicenses } from 'api/licenses'
import { getMarginStructures } from 'api/marginStructures'
import { getPartners } from 'api/partners'
import AccordionItem from 'apps/shared/components/AccordionItem'
import { DeleteButton } from 'apps/shared/components/Buttons'
import { BooleanIcon, InfoIcon } from 'apps/shared/components/Icons'
import { LoadingSpinner } from 'apps/shared/components/LoadingSpinner'
import { ConfirmModal } from 'apps/shared/components/Modals'
import { ConfirmModalBody } from 'apps/shared/components/Modals/ConfirmModal/ConfirmModal.styled'
import NewItemButton from 'apps/shared/components/NewItemButton'
import { SmartSearchAndFilter } from 'apps/shared/components/SearchAndFilter'
import { TransformerFunction } from 'apps/shared/components/SearchAndFilter/SmartSearchAndFilter'
import { LicenseSidebar } from 'apps/vendor/components/Sidebars'
import {
    CustomerType,
    LicenseType,
    MarginStructureType,
    LicenseStatus,
    Term,
    TimePeriod,
    PartnerType,
    ArticleType,
} from 'apps/vendor/interfaces/subscriptions'
import {
    AccordionWrapper,
    BadgeRow,
    NoLicensesInfoIconWrapper,
    HeaderRow,
    RowButtonWrapper,
    ClickBlocker,
} from 'apps/vendor/pages/pages.styled'
import useToast from 'hooks/useToast'

export default function LicensesPage() {
    const [partners, setPartners] = useState<PartnerType[] | []>([])

    const [marginStructures, setMarginStructures] = useState<
        MarginStructureType[] | []
    >([])
    const [customers, setCustomers] = useState<CustomerType[] | []>([])
    const [licenses, setLicenses] = useState<LicenseType[] | []>([])

    const [filteredLicenses, setFilteredLicenses] = useState<
        LicenseType[] | []
    >([])
    const [articles, setArticles] = useState<ArticleType[] | []>([])
    const [showLicenseSidebar, setShowLicenseSidebar] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [loading, setLoading] = useState(true)

    const [selectedLicense, setSelectedLicense] = useState<LicenseType | null>(
        null,
    )
    const { successToast, errorToast } = useToast()

    useEffect(() => {
        setLoading(true)

        Promise.all([
            getPartners(),
            getMarginStructures(),
            getCustomers(),
            getLicenses(),
            getArticles(),
        ])
            .then(
                ([
                    partnersRes,
                    marginStructuresRes,
                    customersRes,
                    licensesRes,
                    articlesRes,
                ]) => {
                    setPartners(partnersRes.data)
                    setMarginStructures(marginStructuresRes.data)
                    setCustomers(customersRes.data)
                    setLicenses(licensesRes.data)
                    setArticles(articlesRes.data)
                    setLoading(false)
                },
            )
            .catch(() => {
                errorToast('Error fetching data')
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateLicenseList = () => {
        getLicenses().then((response) => {
            setLicenses(response.data)
        })
    }

    const onLicenseModalSubmit = () => {
        updateLicenseList()
        setSelectedLicense(null)
        setShowLicenseSidebar(false)
    }

    const handleEditLicense = (license: LicenseType) => {
        setSelectedLicense(license)
        setShowLicenseSidebar(!showLicenseSidebar)
    }

    const handleToggleDeleteModal = (license: LicenseType) => {
        setSelectedLicense(license)
        setShowDeleteModal(!showDeleteModal)
    }

    const handleDeleteLicense = () => {
        if (selectedLicense) {
            deleteLicense(selectedLicense.id)
                .then(() => {
                    updateLicenseList()
                    setSelectedLicense(null)
                    setShowDeleteModal(!showDeleteModal)
                    successToast('License successfully deleted')
                })
                .catch(() => {
                    return errorToast(
                        // eslint-disable-next-line max-len
                        'Unknown error. Please try again and contact Sharlic support if error persists',
                    )
                })
        }
    }

    const getCustomerNameById: TransformerFunction = (id: number) => {
        return (
            customers.find((customer) => customer.id === Number(id))?.name || ''
        )
    }

    const getAccordionHeaders = (license: LicenseType) => {
        return (
            <BadgeRow numberOfColumns={5}>
                <span>{getCustomerNameById(license.customer)}</span>
                <span>{license.name}</span>
                <span>{license.license_key}</span>
                <span className="centered">
                    <BooleanIcon
                        checked={license.status !== LicenseStatus.INACTIVE}
                    />
                </span>
                <RowButtonWrapper>
                    <ClickBlocker
                        onClick={(event) => event.stopPropagation()}
                    />
                    <DeleteButton
                        onClick={(event) => {
                            event.stopPropagation()
                            handleToggleDeleteModal(license)
                        }}
                    />
                </RowButtonWrapper>
            </BadgeRow>
        )
    }

    const renderTable = () => {
        if (loading) return <LoadingSpinner />
        if (loading && !licenses) {
            return null
        }

        if (licenses.length === 0) {
            return (
                <NoLicensesInfoIconWrapper>
                    <InfoIcon />
                    <p>
                        Before adding a license, make sure you have <br />
                        {/* eslint-disable-next-line max-len */}
                        at least one <u>margin structure</u>, one <u>article</u>{' '}
                        and a <u>customer.</u>
                    </p>
                </NoLicensesInfoIconWrapper>
            )
        }

        return (
            <>
                <HeaderRow numberOfColumns={5}>
                    <span>Customer</span>
                    <span>Name</span>
                    <span>License Key</span>
                    <span className="centered">Active</span>
                </HeaderRow>
                <AccordionWrapper>
                    {filteredLicenses.map((license, index) => (
                        <AccordionItem
                            key={license.license_key}
                            eventKey={index.toString()}
                            header={getAccordionHeaders(license)}
                            onClick={() => handleEditLicense(license)}
                        />
                    ))}
                </AccordionWrapper>
            </>
        )
    }

    const getTermInMonths = (term: Term) => {
        const TermInMonths = {
            ONE_MONTH: 1,
            THREE_MONTHS: 3,
            SIX_MONTHS: 6,
            ONE_YEAR: 12,
            EIGHTEEN_MONTHS: 18,
            TWO_YEARS: 24,
            THREE_YEARS: 36,
            FIVE_YEARS: 60,
        }

        return TermInMonths[term]
    }

    const getPaymentFrequencyInMonths = (paymentFrequency: TimePeriod) => {
        const PaymentFrequencyInMonths = {
            MONTHLY: 1,
            QUARTERLY: 3,
            ANNUALLY: 12,
        }

        return PaymentFrequencyInMonths[paymentFrequency]
    }

    const renderConfirmModalBody = () => {
        let info = <span />
        let billingInfo = <span />

        if (!selectedLicense) {
            return <span />
        }

        const termMonths = getTermInMonths(selectedLicense.term)

        const frequencyMonths = getPaymentFrequencyInMonths(
            selectedLicense.payment_frequency,
        )

        if (termMonths > frequencyMonths) {
            billingInfo = (
                <p>
                    Note that this license still has outstanding invoices before
                    end of term.
                </p>
            )
        }

        if (selectedLicense?.status === LicenseStatus.ACTIVE) {
            info = (
                <section>
                    <p>
                        Billing from Sharlic will seize <b>immediately</b>.{' '}
                        {billingInfo}
                    </p>
                </section>
            )
        }

        return (
            <ConfirmModalBody>
                <section>
                    You are about to delete the following license:
                    <p>
                        <b>{selectedLicense?.name}</b>
                    </p>
                </section>
                {info}
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </ConfirmModalBody>
        )
    }

    return (
        <div>
            <NewItemButton
                onButtonClicked={() =>
                    setShowLicenseSidebar(!showLicenseSidebar)
                }
            />
            {showLicenseSidebar && (
                <LicenseSidebar
                    onClose={() => {
                        setShowLicenseSidebar(!showLicenseSidebar)
                        setSelectedLicense(null)
                    }}
                    onSubmit={() => onLicenseModalSubmit()}
                    articles={articles}
                    marginStructures={marginStructures}
                    partners={partners}
                    preselectedLicense={selectedLicense}
                    customers={customers}
                    showSidebar={showLicenseSidebar}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedLicense(null)
                    }}
                    onSubmit={() => handleDeleteLicense()}
                    customSubmitText="Delete"
                    body={renderConfirmModalBody()}
                    deleteButton
                />
            )}
            <SmartSearchAndFilter
                entities={licenses}
                properties={['customer', 'payment_frequency', 'term']}
                handleSetFilteredEntities={setFilteredLicenses}
                propertyTransformers={{
                    customer: getCustomerNameById,
                }}
                recursiveSearch
            />
            {renderTable()}
        </div>
    )
}
