import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const NavItemButton = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;

    button {
        display: flex;
        height: 3em;
        padding: 1em 15px;
        margin: 5px 20px;
        position: relative;
        background-color: transparent;
        color: ${primary.lightGray};
        font-size: 1.2em;
        border: none;
        border-radius: 8px;
        align-items: flex-start;
        transition: all 0.3s linear;

        > * {
            position: absolute;
            left: 2.5em;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: 500;
            color: ${primary.darkBlue};
            pointer-events: none;
        }

        &.active:not(.subItemNavButton):not(.no-active-bg) {
            background-color: ${secondary.paleBlue};
        }

        &:hover:not(.subItemNavButton) {
            background-color: ${secondary.whisperWhite};
        }
    }

    @media screen and (max-height: 1000px) {
        button {
            margin: 0 20px;
        }
    }
`

export const NavItemWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;

    @media screen and (min-width: 768px) and (min-height: 750px) {
        &.bottom-nav-item {
            position: absolute;
            bottom: 0;
            padding-bottom: 1em;
        }
    }
`
