import React from 'react'

import { SaveButtonWrapper } from './SaveButton.styled'

export default function SaveButton(defaultProps: {
    onClick: () => void
    canSubmit?: boolean
}) {
    const { onClick, canSubmit } = defaultProps

    return (
        <SaveButtonWrapper onClick={onClick} disabled={!canSubmit}>
            Save
        </SaveButtonWrapper>
    )
}
