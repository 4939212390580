import styled from 'styled-components'
import { secondary } from 'utils/colors'

export const AccordionItemWrapper = styled.div`
    .no-toggle .accordion-button::after {
        display: none !important;
    }

    .accordion-button:focus {
        box-shadow: none !important;
        outline: none !important;
        background-color: ${secondary.paleBlue} !important;
    }

    .accordion-button:not(.collapsed) {
        background-color: transparent !important;
        color: inherit !important;
    }
`
