import React from 'react'
import { Modal } from 'react-bootstrap'

import { TitleWrapper } from './Modal.styled'

export interface ModalProps {
    title: JSX.Element | string
    onClose: () => void
    body: JSX.Element | JSX.Element[]
    footer?: JSX.Element | JSX.Element[] | null
    size?: 'sm' | 'lg' | 'xl'
    isCentered?: boolean
}

export default function sharlicModal(props: ModalProps) {
    const { title, onClose, body, footer, size, isCentered } = props

    return (
        <Modal
            show
            onHide={onClose}
            size={size}
            backdrop="static"
            centered={isCentered}
        >
            <Modal.Header closeButton>
                <TitleWrapper>{title}</TitleWrapper>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>{footer}</Modal.Footer>
        </Modal>
    )
}
