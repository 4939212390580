import { Vendor } from 'state/vendor/VendorState'

export enum Term {
    ONE_MONTH = 'ONE_MONTH',
    THREE_MONTHS = 'THREE_MONTHS',
    SIX_MONTHS = 'SIX_MONTHS',
    ONE_YEAR = 'ONE_YEAR',
    EIGHTEEN_MONTHS = 'EIGHTEEN_MONTHS',
    TWO_YEARS = 'TWO_YEARS',
    THREE_YEARS = 'THREE_YEARS',
    FIVE_YEARS = 'FIVE_YEARS',
}

export enum TimePeriod {
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
    ANNUALY = 'ANNUALLY',
}

export enum CountryCode {
    SE = 'SE',
    US = 'US',
}

export enum Language {
    SWEDISH = 'sv-SE',
    ENGLISH = 'en-US',
}

export enum Currency {
    SEK = 'SEK',
    USD = 'USD',
    EUR = 'EUR',
}

export enum LicenseStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DELETED = 'DELETED',
    MANUAL = 'MANUAL',
}

export enum CustomerStatus {
    COMPLETE = 'COMPLETE',
    INCOMPLETE = 'INCOMPLETE',
    DELETED = 'DELETED',
}

export enum LicenseNotification {
    ON_RENEWAL = 'notification.on_renewal',
    ON_INVOICING = 'notification.on_invoicing',
    ON_PAID = 'notification.on_paid',
    OVERDUE = 'notification.overdue',
    REMINDER = 'notification.reminder',
}

export interface RevenueDistributionType {
    vendor: string
    vendor_share: number
    sharlic_fee: number
    share_pre_fee: number
    share_percentage: number
}

export interface RecordType {
    id?: number
    email: string
    lead_days: number | null
    notification_config?: number
    type: LicenseNotification
}

export interface LicenseNotificationConfigType {
    id?: number
    license_notification_config_records: RecordType[]
}

export interface LicenseType {
    id: number
    customer: number
    name: string
    license_key: string | number
    vendor: number
    articles: [ArticleSetType]
    rebate: number
    markup: number
    payment_frequency: TimePeriod
    term: Term
    start_date: string
    sharlic_invoice_period_start_date: string
    next_invoice_due_date?: string
    last_invoice_due_date?: string
    sales_tiers: SalesTierType[]
    margin_structure?: MarginStructureType
    total_msrp: number
    total_price: number
    revenue_distribution?: RevenueDistributionType[]
    status: LicenseStatus
    manual_invoicing: boolean
    invoice_status?: string
}

export interface CustomerInvoiceType {
    id: number
    status: string
    stripe_invoice_number: string
    created_at: string
    due_date: string
    invoice_total: string
}

export interface CustomerType {
    id: number
    name: string
    external_customer_id: string
    reference: string
    contact_email: string
    description: string
    country: CountryCode
    address_line_1: string
    address_line_2: string
    zip_code: string
    city: string
    state: string
    phone_number: string
    language: Language
    currency: Currency
    created_at: string
    taxable: boolean
    status: CustomerStatus
    invoices?: CustomerInvoiceType[]
    related_licenses?: { id: number; name: string }[]
    tax_id?: string
    tax_type?: string
}

export interface SharlicFixedFeeType {
    amount: number
    currency: string
}

export interface MarginStructureType {
    id: number
    name: string
    description: string
    number_of_sales_tiers: number
    percentage_per_tier: string
    sharlic_share_percentage: number
    sharlic_fixed_fee: SharlicFixedFeeType
    available_for_partners: boolean
    is_default: boolean
    created_at: string
    related_licenses?: { id: number; name: string; vendor: string }[]
}

export interface SalesTierType {
    order: number
    vendor: Vendor | PartnerType
}

export interface CustomerSubscriptionDataType {
    id: number
    name: string
    licenses: LicenseType[]
    latitude?: number
    longitude?: number
}

export interface SubscriptionAPIType {
    data: CustomerSubscriptionDataType[]
    status: number
    statusText: string
}

export interface GeneratedHeader {
    id: number
    customer: CustomerType
    arr: number
    nextInvoice: string
    invoiceValue: string
    valueSinceStart: number
}

export interface GeneratedArticle {
    id: number
    licenseKey: string
    article: string
    quantity: number
    price: number
    paymentFreq: number
}

export interface GeneratedSubscriptionList {
    header: GeneratedHeader
    articles: GeneratedArticle[]
}

export interface PartnerType {
    id: number
    partner_name: string
    contact_email: string
    created_at: string
    latitude?: number
    longitude?: number
    is_deletable?: boolean
    related_licenses?: { id: number; name: string; vendor: string }[]
}

export interface ArticleType {
    id: number
    external_id: string
    name: string
    description: string
    msrp: number | string
    currency: string
    available_for_partners: boolean
    vendor: number
    related_licenses?: { id: number; name: string; vendor: string }[]
    ownedByPartner?: boolean
}

export interface ArticleSetType {
    quantity: number
    article: ArticleType
    description?: string
}
