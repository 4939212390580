import styled from 'styled-components'

export const OuterGrid = styled.div`
    display: grid;
    grid-template-rows: 66px auto;
`

export const Grid = styled.div`
    display: flex;
    flex-direction: row;

    > *:not(not-scrollable) {
        height: calc(100vh - 66px);
    }
`

export const ContentWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`

export const MainContent = styled.div`
    @media screen and (min-width: 991px) {
        padding: 1em 2em;
    }
    @media screen and (max-width: 990px) {
        padding: 1em;
        margin: 0;
    }
`
