import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { ProfileSidebar } from 'apps/vendor/components/Sidebars'
import useAppDispatch from 'hooks/useAppDispatch'
import useUser from 'hooks/useUser'
import { logoutUser } from 'state/user/actions'

import { SharlicWhiteLogo } from '../Logo'
import {
    CircleIcon,
    PageHeadWrapper,
    SettingsDropdown,
    BadgeContainer,
    DisplayIconContainer,
    DisplayName,
    LogoSection,
} from './PageHeader.styled'

export default function PageHeader() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { user } = useUser()
    const [showProfileModal, setShowProfileModal] = useState(false)

    const logout = () => {
        dispatch(logoutUser()).then(() => {
            navigate(0)
        })
    }

    const renderUserIcon = () => {
        if (!user) return null

        const hasUserAndName = user && user.first_name && user.last_name

        const userInitials = hasUserAndName ? (
            `${user.first_name[0]}${user.last_name[0]}`
        ) : (
            <i className="fa-solid fa-user" />
        )

        const userName = hasUserAndName
            ? `${user?.first_name} ${user?.last_name}`
            : ''

        return (
            <DisplayIconContainer>
                <>
                    <CircleIcon className="display-icon">
                        {userInitials}
                    </CircleIcon>
                    <DisplayName className="display-name">
                        {userName}
                    </DisplayName>
                </>
            </DisplayIconContainer>
        )
    }

    const renderUserContainer = () => {
        return (
            <BadgeContainer>
                <SettingsDropdown className="settings-dropdown">
                    <Dropdown.Toggle>{renderUserIcon()}</Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => setShowProfileModal(true)}
                        >
                            <div className="fa-solid fa-user profile" />
                            Profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={logout}>
                            <div
                                className="
                            fa-solid fa-right-from-bracket sign-out"
                            />
                            Sign Out
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </SettingsDropdown>
            </BadgeContainer>
        )
    }

    return (
        <>
            {showProfileModal && (
                <ProfileSidebar
                    onClose={() => setShowProfileModal(!showProfileModal)}
                    onSubmit={() => setShowProfileModal(!showProfileModal)}
                    showSidebar={showProfileModal}
                />
            )}
            <PageHeadWrapper>
                <LogoSection>
                    <SharlicWhiteLogo />
                </LogoSection>
                {renderUserContainer()}
            </PageHeadWrapper>
        </>
    )
}
