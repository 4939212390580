import React from 'react'

import { DownloadPdfButtonWrapper } from './DownloadPdfButton.styled'

type PdfButtonProps = {
    onClick: () => void
}

export default function DownloadPdfButton(defaultProps: PdfButtonProps) {
    const { onClick } = defaultProps

    return (
        <DownloadPdfButtonWrapper
            type="button"
            onClick={() => onClick()}
            aria-label="Open PDF"
        >
            <i className="fa-solid fa-download" />
        </DownloadPdfButtonWrapper>
    )
}
