import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const ConfigCheckboxSection = styled.section`
    > label {
        margin-top: 0.5rem;
        margin-bottom: -0.5rem;
    }
    > section {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
`

export const ConfigCard = styled.div`
    border: 1px solid ${secondary.lightGray};
    border-radius: 8px;
    padding: 0.5rem;
    position: relative;
    margin-right: 5px;

    .react-multi-email {
        max-height: fit-content;
        width: 98%;
        padding: 0, 0.4rem;
        margin-top: -0.2rem;

        > * {
            height: 100%;
            border-radius: 5px;
        }
    }

    .notification-info-emails {
        width: 98%;
        border-radius: 5px;
        border: 1px solid ${secondary.lightGray};
        display: flex;
        font-size: 0.9rem;
        height: 46px;
        gap: 0.2rem;
        margin-top: -0.2rem;
        margin-right: 0.5rem;
        overflow: scroll;
        scrollbar-gutter: stable;
        -webkit-overflow-scrolling: auto;

        /* For Webkit browsers (Chrome, Safari) */
        &::-webkit-scrollbar {
            height: 0.4rem;
        }

        &::-webkit-scrollbar-thumb {
            background: ${secondary.lightGray};
            border-radius: 4px;
        }

        > p {
            all: unset;
            font-weight: 500;
            font-size: 0.8rem;
            background-color: ${secondary.whisperWhite};
            border-radius: 5px;
            color: ${primary.darkGray};
            height: 22px;
            margin: 10px 0 0 0;
            padding: 2px 31px 0 11px;

            :first-child {
                margin-left: 10px;
            }
        }
    }

    .notification-options {
        border-top: 1px solid ${secondary.lightGray};
        margin-top: 1rem;
        padding-top: 0.5rem;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .kebab {
            margin-top: -0.5rem;
            margin-bottom: -0.5rem;
        }
    }

    .card-footer {
        display: flex;
        justify-content: end;
        margin-top: -2.3rem;
        gap: 0.5rem;

        > button {
            font-size: 0.8rem;
        }
    }

    &.disabled {
        pointer-events: none;

        .card-header {
            button {
                color: ${primary.lightGray};
            }
        }
    }

    &.editing {
        outline: 2px solid ${primary.blue};
        outline-offset: -2px;

        .card-footer {
            margin-bottom: 4px;
        }
    }
`
