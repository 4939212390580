import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

import { inactivateTwoFactor } from 'api/users'
import { OneTimeTokenField } from 'apps/shared/components/FormInputFields'
import { Modal } from 'apps/shared/components/Modals'
import { CancelSubmitFooter } from 'apps/shared/components/Modals/Footers'
import { ModalWrapper } from 'apps/shared/components/Modals/Modal.styled'
import { TwoFactorAuthForm } from 'apps/vendor/components/Modals/TwoFactorModals/ActivateAuth.styled'
import useToast from 'hooks/useToast'
import useUser from 'hooks/useUser'

type InactivateTwoFactorModalProps = {
    onClose: () => void
}

export default function InactivateTwoFactorModal(
    defaultProps: InactivateTwoFactorModalProps,
) {
    const { onClose } = defaultProps
    const { successToast, errorToast } = useToast()
    const { refetchUser } = useUser()
    const [totp, setTotp] = useState('')

    const handleSubmit = async () => {
        inactivateTwoFactor({ totp_code: totp })
            .then((response) => {
                if (response.status === 200) {
                    successToast('Inactivated 2FA successfully')
                    refetchUser()
                    onClose()
                }
            })
            .catch(() => {
                errorToast('Invalid code')
                setTotp('')
            })
    }

    const handleTokenChange = (e: string) => {
        setTotp(e)
    }

    const renderTwoFactorAuthForm = () => {
        return (
            <ModalWrapper>
                <TwoFactorAuthForm>
                    <Form
                        onSubmit={handleSubmit}
                        onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
                    >
                        <div className="login-component">
                            <p className="textInfo">
                                Enter code from Authenticator app to disable 2FA
                            </p>
                            <OneTimeTokenField onChange={handleTokenChange} />
                        </div>
                    </Form>
                </TwoFactorAuthForm>
            </ModalWrapper>
        )
    }

    return (
        <Modal
            onClose={onClose}
            title="Inactivate Two Factor Authentication"
            body={renderTwoFactorAuthForm()}
            footer={
                <CancelSubmitFooter
                    customSubmitText="Submit"
                    onClose={onClose}
                    onSubmit={() => handleSubmit()}
                    canSubmit={totp.length === 6}
                />
            }
            size="lg"
        />
    )
}
