import React from 'react'

import { DeleteButtonWrapper } from './DeleteButton.styled'

interface DeleteButtonProps {
    onClick: (event?: any) => void
}

export default function DeleteButton({ onClick }: DeleteButtonProps) {
    return (
        <DeleteButtonWrapper
            className="fa-solid fa-trash-can"
            onClick={(event) => onClick?.(event)}
        />
    )
}
