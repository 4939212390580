/* eslint-disable max-len */
import React from 'react'
import { Button } from 'react-bootstrap'

import { NewItemButtonWrapper } from './NewItemButton.styled'

export default function NewItemButton(defaultProps: {
    header?: string
    onButtonClicked?: () => void
}) {
    const { onButtonClicked } = defaultProps

    return (
        <NewItemButtonWrapper>
            {onButtonClicked && (
                <Button
                    className="btn-lg absolute-button"
                    onClick={() => onButtonClicked()}
                >
                    + New
                </Button>
            )}
        </NewItemButtonWrapper>
    )
}
