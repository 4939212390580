import React, { useEffect, useRef, useState } from 'react'
import { Form, FormText, InputGroup } from 'react-bootstrap'

import { addMarginStructure, editMarginStructure } from 'api/marginStructures'
import { NumberInput } from 'apps/shared/components/FormInputFields'
import {
    Checkbox,
    ModalWrapper,
} from 'apps/shared/components/Modals/Modal.styled'
import { ErrorMessage, FormLabel } from 'apps/shared/shared.styled'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import Sidebar from 'apps/vendor/components/Sidebars/Sidebar'
import { MarginStructureType } from 'apps/vendor/interfaces/subscriptions'
import useToast from 'hooks/useToast'

import {
    PercentageInputWrapper,
    SalesTierPercentageWrapper,
    SalesTierTotalPercentage,
    StyledInputGroupText,
} from './MarginStructureSidebar.styled'

interface MarginStructureSidebarProps {
    onClose: () => void
    onSubmit: () => void
    preselectedMarginStructure?: MarginStructureType | null
    showSidebar: boolean
}

export default function MarginStructureSidebar(
    defaultProps: MarginStructureSidebarProps,
) {
    const { onClose, onSubmit, preselectedMarginStructure, showSidebar } =
        defaultProps
    const [name, setName] = useState(preselectedMarginStructure?.name || '')
    const [nameError, setNameError] = useState('')

    const [description, setDescription] = useState(
        preselectedMarginStructure?.description || '',
    )

    const [numberOfSalesTiers, setNumberOfSalesTiers] = useState<number>(
        preselectedMarginStructure?.number_of_sales_tiers || 1,
    )

    const [percentagePerTier, setPercentagePerTier] = useState<string[]>(
        preselectedMarginStructure?.percentage_per_tier
            ? preselectedMarginStructure?.percentage_per_tier.split(',')
            : ['100'],
    )
    const [canSubmit, setCanSubmit] = useState(true)

    const [availableForPartners, setAvailableForPartners] = useState(
        preselectedMarginStructure?.available_for_partners || false,
    )

    const [isDefault, setIsDefault] = useState(
        preselectedMarginStructure?.is_default || false,
    )
    const { errorToast, successToast } = useToast()
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    useEffect(() => {
        let isEdited = true
        if (preselectedMarginStructure) {
            isEdited =
                name !== preselectedMarginStructure.name ||
                description !== preselectedMarginStructure.description ||
                numberOfSalesTiers !==
                    preselectedMarginStructure.number_of_sales_tiers ||
                percentagePerTier.join(',') !==
                    preselectedMarginStructure.percentage_per_tier ||
                availableForPartners !==
                    preselectedMarginStructure.available_for_partners ||
                isDefault !== preselectedMarginStructure.is_default
        }

        const totalPercentage = percentagePerTier.reduce(
            (total, nextValue) => total + Number(nextValue),
            0,
        )

        const requiredFieldsSet = name !== ''
        const hasErrors = totalPercentage !== 100 || nameError !== ''

        setCanSubmit(requiredFieldsSet && isEdited && !hasErrors)
    }, [
        name,
        description,
        numberOfSalesTiers,
        availableForPartners,
        isDefault,
        preselectedMarginStructure,
        percentagePerTier,
        nameError,
    ])

    const handleSubmit = () => {
        if (!canSubmit || !numberOfSalesTiers) {
            return
        }

        const marginStructureData = {
            name,
            description,
            number_of_sales_tiers: numberOfSalesTiers,
            percentage_per_tier: percentagePerTier.join(','),
            available_for_partners: availableForPartners,
            is_default: isDefault,
        }

        const apiCall = preselectedMarginStructure
            ? editMarginStructure(
                  marginStructureData,
                  preselectedMarginStructure?.id,
              )
            : addMarginStructure(marginStructureData)

        apiCall
            .then(() => {
                onSubmit()

                successToast(
                    preselectedMarginStructure
                        ? 'Margin structure successfully edited.'
                        : 'Margin structure successfully added.',
                )
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    return setNameError(
                        'Margin structure with this name already exists.',
                    )
                }
                if (err.response.status !== 500) {
                    return errorToast(
                        preselectedMarginStructure
                            ? 'Failed to edit margin structure,' +
                                  ' please try again.'
                            : 'Failed to create margin structure,' +
                                  ' please try again.',
                    )
                }

                return errorToast(
                    'Unknown error. Please try again and contact' +
                        ' Sharlic support if error persists.',
                )
            })
    }

    const handleSetNumberOfSalesTiers = (selectedTier: number) => {
        setNumberOfSalesTiers(selectedTier)
        if (selectedTier !== 1) {
            setPercentagePerTier(Array(selectedTier).fill('0'))

            return
        }
        setPercentagePerTier(['100'])
    }

    const handleSetSalesTierPercentage = (tier: number, percentage: string) => {
        const value = percentage

        setPercentagePerTier((prevState) => {
            const tempSalesTiers = [...prevState]
            tempSalesTiers[tier] = value

            return tempSalesTiers
        })
    }

    const renderTotalSalesTierPercentage = () => {
        const value = percentagePerTier.reduce(
            (a, b) => Number(a) + Number(b),
            0,
        )

        return (
            <SalesTierTotalPercentage valid={value === 100}>
                {value}%
            </SalesTierTotalPercentage>
        )
    }

    const renderSalesTierPercentageFields = () => {
        const salesTiers = ['Producer']

        if (numberOfSalesTiers > 1) {
            salesTiers.push('Reseller')
        }

        if (numberOfSalesTiers === 3) {
            salesTiers.splice(1, 0, 'Distributor')
        }

        return (
            <SalesTierPercentageWrapper>
                <PercentageInputWrapper>
                    {salesTiers.map((label, index) => (
                        <div key={label}>
                            <FormText>{label}</FormText>
                            <InputGroup>
                                <NumberInput
                                    value={percentagePerTier[index]}
                                    onChange={(value: string) =>
                                        handleSetSalesTierPercentage(
                                            index,
                                            value,
                                        )
                                    }
                                    disabled={
                                        numberOfSalesTiers === 1 ||
                                        index + 1 > numberOfSalesTiers
                                    }
                                    config={{
                                        min: 0,
                                        max: 999,
                                        allowEmpty: false,
                                        allowLeadingZero: false,
                                        allowDecimal: {
                                            isEnabled: false,
                                        },
                                    }}
                                />
                                <StyledInputGroupText>%</StyledInputGroupText>
                            </InputGroup>
                        </div>
                    ))}
                    {renderTotalSalesTierPercentage()}
                </PercentageInputWrapper>
            </SalesTierPercentageWrapper>
        )
    }

    const renderSalesTiersDropdown = () => {
        const options = [
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
        ]

        const selectedOption = options.find(
            (option) => option.value === numberOfSalesTiers,
        )

        const value = selectedOption
            ? {
                  value: selectedOption.value,
                  label: selectedOption.label.toString(),
              }
            : null

        return (
            <>
                <FormLabel>Sales tiers</FormLabel>
                <SelectDropdown
                    options={options}
                    value={value}
                    onChange={handleSetNumberOfSalesTiers}
                />
            </>
        )
    }

    const renderNameAndDescriptionFields = () => {
        return (
            <>
                <FormLabel>Name</FormLabel>
                <Form.Control
                    {...{
                        ref: inputRef,
                        placeholder: 'Margin structure name',
                        value: name,
                        type: 'text',
                        onChange: (e: any) => {
                            setName(e.target.value)
                            setNameError('')
                        },
                        onBlur: (e: any) => setName(e.target.value.trim()),
                    }}
                />
                {nameError && <ErrorMessage>{nameError}</ErrorMessage>}
                <FormLabel>
                    Description <span> (optional)</span>
                </FormLabel>
                <Form.Control
                    {...{
                        value: description,
                        type: 'text',
                        onChange: (e: any) => setDescription(e.target.value),
                        onBlur: (e: any) =>
                            setDescription(e.target.value.trim()),
                    }}
                />
            </>
        )
    }

    const renderBody = () => {
        return (
            <ModalWrapper>
                {renderNameAndDescriptionFields()}
                {renderSalesTiersDropdown()}
                {renderSalesTierPercentageFields()}
                <Checkbox
                    {...{
                        id: 'available-for-partners',
                        name: 'available-for-partners-check',
                        label: 'Available for partners',
                        defaultChecked: availableForPartners,
                        onChange: () =>
                            setAvailableForPartners(!availableForPartners),
                    }}
                />
                <Checkbox
                    {...{
                        id: 'is-default',
                        name: 'default-check',
                        label: 'Default margin structure',
                        defaultChecked: isDefault,
                        onChange: () => setIsDefault(!isDefault),
                    }}
                />
            </ModalWrapper>
        )
    }

    return (
        <Sidebar
            title={preselectedMarginStructure ? 'Edit' : 'New'}
            body={renderBody()}
            onClose={onClose}
            onSubmit={handleSubmit}
            showSidebar={showSidebar}
            canSubmit={canSubmit}
        />
    )
}
