import React, { useEffect, useRef, useState } from 'react'
import { Button, Alert, Form, Tab } from 'react-bootstrap'

import { editCustomer, addCustomer } from 'api/customers'
import { getCustomerDocuments } from 'api/customers/documents'
import { getInvoices } from 'api/invoices'
import { CountryType } from 'api/utils/interfaces'
import {
    EmailInput,
    PhoneInputField,
} from 'apps/shared/components/FormInputFields'
import { CountryFlagIcon } from 'apps/shared/components/Icons/CountryFlag'
import {
    ModalWrapper,
    MultipleSection,
} from 'apps/shared/components/Modals/Modal.styled'
import { ErrorMessage, FormLabel, TabWrapper } from 'apps/shared/shared.styled'
import { FileUploadModal } from 'apps/vendor/components/Modals'
import { SelectDropdown } from 'apps/vendor/components/Selects'
import {
    InvoicesTable,
    CustomerDocumentsTable,
} from 'apps/vendor/components/Tables'
import { currencyOptions } from 'apps/vendor/constants'
import { CustomerDocumentType } from 'apps/vendor/interfaces/documents'
import {
    CountryCode,
    Currency,
    CustomerStatus,
    CustomerType,
    Language,
} from 'apps/vendor/interfaces/subscriptions'
import useCountryData from 'hooks/useCountryData'
import useToast from 'hooks/useToast'
import isEmail from 'validator/lib/isEmail'

import { TaxInformationContainer } from '../../TaxInformationContainer'
import Sidebar from '../Sidebar/Sidebar'
import {
    BillingSection,
    CustomerDocumentTabWrapper,
    WarningMessage,
} from './CustomerSidebar.styled'

interface CustomerSidebarProps {
    onClose: () => void
    onSubmit: () => void
    preselectedCustomer?: CustomerType | null
    customers?: CustomerType[]
    showSidebar: boolean
}

export default function CustomerSidebar(defaultProps: CustomerSidebarProps) {
    const { onClose, onSubmit, preselectedCustomer, customers, showSidebar } =
        defaultProps
    const { countryData } = useCountryData()
    const [name, setName] = useState(preselectedCustomer?.name || '')
    const [nameError, setNameError] = useState('')

    const [customerId, setCustomerId] = useState<string | null>(
        preselectedCustomer?.external_customer_id || null,
    )
    const [idError, setIdError] = useState('')

    const [reference, setReference] = useState(
        preselectedCustomer?.reference || '',
    )
    const [email, setEmail] = useState(preselectedCustomer?.contact_email || '')
    const [emailError, setEmailError] = useState('')
    const [emailWarning, setEmailWarning] = useState('')

    const [description, setDescription] = useState(
        preselectedCustomer?.description || '',
    )

    const [address1, setAddress1] = useState(
        preselectedCustomer?.address_line_1 || '',
    )

    const [address2, setAddress2] = useState(
        preselectedCustomer?.address_line_2 || '',
    )
    const [city, setCity] = useState(preselectedCustomer?.city || '')
    const [state, setState] = useState(preselectedCustomer?.state || '')

    const [countryCode, setCountryCode] = useState(
        preselectedCustomer?.country || '',
    )
    const [zipCode, setZipCode] = useState(preselectedCustomer?.zip_code || '')

    const [phoneNumber, setPhoneNumber] = useState(
        preselectedCustomer?.phone_number || '',
    )
    const [phoneError, setPhoneError] = useState('')

    const [language, setLanguage] = useState(
        preselectedCustomer?.language || '',
    )

    const [currency, setCurrency] = useState(
        preselectedCustomer?.currency || '',
    )

    const [taxable, setTaxable] = useState<boolean>(
        preselectedCustomer?.taxable || false,
    )
    const [documents, setDocuments] = useState<CustomerDocumentType[]>([])
    const [canSubmit, setCanSubmit] = useState(true)
    const { errorToast, successToast } = useToast()
    const inputRef = useRef<HTMLInputElement>(null)
    const [activeTab, setActiveTab] = useState('customer')
    const [showUploadFileModal, setShowUploadFileModal] = useState(false)
    const [refreshDocuments, setRefreshDocuments] = useState(false)
    const [taxId, setTaxId] = useState(preselectedCustomer?.tax_id || '')
    const [isValidTaxId, setIsValidTaxId] = useState(true)
    const [taxType, setTaxType] = useState(preselectedCustomer?.tax_type || '')
    const [showCustomerAlert, setShowCustomerAlert] = useState(false)
    const [requiredFieldsSet, setRequiredFieldsSet] = useState(false)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }

        if (preselectedCustomer) {
            getInvoices(preselectedCustomer.id)
                .then((res: any) => {
                    if (res.data !== preselectedCustomer.invoices) {
                        preselectedCustomer.invoices = res.data.reverse()
                    }
                })
                .catch(() => {
                    errorToast('Failed to fetch invoices')
                })

            getCustomerDocuments(preselectedCustomer.id)
                .then((res: any) => {
                    if (res.data !== documents) {
                        setDocuments(res.data.reverse())
                    }
                })
                .catch(() => {
                    errorToast('Failed to fetch documents')
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let isEdited = false

        const hasErrors =
            emailError !== '' ||
            phoneError !== '' ||
            idError !== '' ||
            nameError !== '' ||
            !isValidTaxId

        isEdited =
            name !== (preselectedCustomer?.name || '') ||
            customerId !==
                (preselectedCustomer?.external_customer_id || null) ||
            reference !== (preselectedCustomer?.reference || '') ||
            email !== (preselectedCustomer?.contact_email || '') ||
            description !== (preselectedCustomer?.description || '') ||
            countryCode !== (preselectedCustomer?.country || '') ||
            address1 !== (preselectedCustomer?.address_line_1 || '') ||
            address2 !== (preselectedCustomer?.address_line_2 || '') ||
            zipCode !== (preselectedCustomer?.zip_code || '') ||
            city !== (preselectedCustomer?.city || '') ||
            state !== (preselectedCustomer?.state || '') ||
            phoneNumber !== (preselectedCustomer?.phone_number || '') ||
            language !== (preselectedCustomer?.language || '') ||
            currency !== (preselectedCustomer?.currency || '') ||
            taxable !== (preselectedCustomer?.taxable || false) ||
            taxId !== (preselectedCustomer?.tax_id || '') ||
            taxType !== (preselectedCustomer?.tax_type || '')

        const isRequiredFieldsSet =
            name !== '' &&
            email !== '' &&
            countryCode !== '' &&
            address1 !== '' &&
            zipCode !== '' &&
            city !== '' &&
            (countryCode === 'US' ? state !== '' : true) &&
            language !== '' &&
            currency !== '' &&
            (!taxable || (taxId !== '' && taxType !== ''))

        setRequiredFieldsSet(isRequiredFieldsSet)
        const isValidEmail = email !== '' ? isEmail(email) : true

        setShowCustomerAlert(!isRequiredFieldsSet && isEdited)

        setCanSubmit(
            isEdited &&
                isValidEmail &&
                !phoneError &&
                !hasErrors &&
                (preselectedCustomer?.status !== CustomerStatus.COMPLETE ||
                    isRequiredFieldsSet),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        name,
        nameError,
        customerId,
        idError,
        reference,
        email,
        description,
        countryCode,
        address1,
        address2,
        zipCode,
        city,
        state,
        phoneNumber,
        phoneError,
        language,
        currency,
        emailError,
        taxable,
        taxId,
        taxType,
        isValidTaxId,
    ])

    const handleSubmit = () => {
        if (!canSubmit) {
            return
        }

        const customerData = {
            name,
            external_customer_id: customerId,
            reference,
            contact_email: email,
            description,
            country: countryCode as CountryCode,
            address_line_1: address1,
            address_line_2: address2,
            zip_code: zipCode,
            city,
            state,
            phone_number: phoneNumber,
            language: language as Language,
            currency: currency as Currency,
            tax_id: taxId,
            tax_type: taxType,
            taxable,
        }

        const apiCall = preselectedCustomer
            ? editCustomer(customerData, preselectedCustomer.id)
            : addCustomer(customerData)

        apiCall
            .then(() => {
                onSubmit()

                successToast(
                    preselectedCustomer
                        ? 'Customer updated successfully.'
                        : 'Customer created successfully.',
                )
            })
            .catch((err) => {
                let specificErrorHandled = false

                if (err.response.data.contact_email) {
                    setEmailError('Invalid email.')
                    specificErrorHandled = true
                }
                if (err.response.data.phone_number) {
                    return errorToast('Invalid phone number.')
                }

                if (err.response.status !== 500) {
                    const errorTexts = err.response.data.non_field_errors

                    errorTexts?.forEach((errorText: string) => {
                        if (
                            errorText ===
                            'The fields name, vendor must make a unique set.'
                        ) {
                            setNameError(
                                'Customer with this name already exists.',
                            )
                            specificErrorHandled = true
                        }
                        if (
                            errorText ===
                            // eslint-disable-next-line max-len
                            'The fields external_customer_id, vendor must make a unique set.'
                        ) {
                            setIdError('Customer with this ID already exists.')
                            specificErrorHandled = true
                        }
                    })

                    if (!specificErrorHandled) {
                        return errorToast(
                            preselectedCustomer
                                ? 'Failed to update customer, please try again.'
                                : 'Failed to create customer, ' +
                                      'please try again.',
                        )
                    }
                }

                return errorToast(
                    // eslint-disable-next-line max-len
                    'Unknown error. Please try again and contact Sharlic support if error persists.',
                )
            })
    }

    const checkIfEmailExists = (newEmail: string) => {
        if (
            customers &&
            customers.some((customer) => customer.contact_email === newEmail)
        ) {
            setEmailWarning(
                `You already have a customer with this invoice email
                registered. Please verify email is correct before proceeding.`,
            )

            return
        }

        setEmailWarning('')
    }

    const handleSetEmail = (value: string) => {
        setEmail(value)
        checkIfEmailExists(value)
        setEmailError('')
    }

    const generateTextInputField = (
        value: string,
        action: any,
        label?: string,
        optional?: boolean,
    ) => {
        return (
            <>
                {label && (
                    <FormLabel>
                        {label}
                        {optional ? <span> (optional)</span> : null}
                    </FormLabel>
                )}
                <Form.Control
                    {...{
                        value,
                        type: 'text',
                        onChange: (e: any) => action(e.target.value),
                        onBlur: (e: any) => action(e.target.value.trim()),
                    }}
                />
            </>
        )
    }

    const handleSetName = (value: string) => {
        setNameError('')
        setName(value)
    }

    const renderNameField = () => {
        return (
            <>
                <FormLabel>Name</FormLabel>
                <Form.Control
                    {...{
                        ref: inputRef,
                        value: name,
                        type: 'text',
                        onChange: (e: any) => handleSetName(e.target.value),
                        onBlur: (e: any) =>
                            handleSetName(e.target.value.trim()),
                    }}
                />
                {nameError && <ErrorMessage>{nameError}</ErrorMessage>}
            </>
        )
    }

    const handleSetCustomerID = (value: string) => {
        setIdError('')
        if (!value) {
            setCustomerId(null)

            return
        }

        setCustomerId(value)
    }

    const renderExternalCustomerIdField = () => {
        return (
            <>
                <FormLabel>
                    Customer ID<span> (optional)</span>
                </FormLabel>
                <Form.Control
                    {...{
                        value: customerId?.toString() || '',
                        type: 'text',
                        onChange: (e: any) =>
                            handleSetCustomerID(e.target.value),
                        onBlur: (e: any) =>
                            handleSetCustomerID(e.target.value.trim()),
                    }}
                />
                {idError && <ErrorMessage>{idError}</ErrorMessage>}
            </>
        )
    }

    const renderReferenceField = () => {
        return (
            <>
                <FormLabel>
                    Reference<span> (optional)</span>
                </FormLabel>
                <Form.Control
                    {...{
                        value: reference,
                        type: 'text',
                        onChange: (e: any) => setReference(e.target.value),
                        onBlur: (e: any) => setReference(e.target.value.trim()),
                    }}
                />
            </>
        )
    }

    const renderEmailField = () => {
        return (
            <>
                <FormLabel>Email</FormLabel>
                <EmailInput
                    {...{
                        value: email,
                        onChange: (value: string) => handleSetEmail(value),
                    }}
                />
                {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
                {emailWarning && (
                    <WarningMessage>{emailWarning}</WarningMessage>
                )}
            </>
        )
    }

    const renderDescriptionField = () => {
        return (
            <>
                {generateTextInputField(
                    description,
                    setDescription,
                    'Description',
                    true,
                )}
            </>
        )
    }

    const handleSetCountry = (code: CountryCode) => {
        setCountryCode(code)
        setState('')
        setPhoneError('')

        setPhoneNumber('')
    }

    const countries: CountryType[] = countryData
        ? Object.keys(countryData.country_data).map((code) => ({
              code,
              name: countryData.country_data[code].country,
          }))
        : []

    const renderCountryDropdown = () => {
        const options = countries.map((country) => ({
            value: country.code,
            label: <CountryFlagIcon code={country.code} label={country.name} />,
        }))

        const selectedOption = options.find(
            (option) => option.value === countryCode,
        )

        const value = selectedOption || null

        return (
            <SelectDropdown
                options={options}
                value={value}
                onChange={handleSetCountry}
                placeholder="Country"
                isSearchable
            />
        )
    }

    const renderLanguageDropdown = () => {
        const languages = [
            { code: 'sv-SE', name: 'Swedish' },
            { code: 'en-US', name: 'English' },
        ]

        const options = languages.map((lang) => ({
            value: lang.code,
            label: lang.name,
        }))

        const value =
            options.find((option) => option.value === language) || null

        return (
            <>
                <FormLabel>Language</FormLabel>
                <SelectDropdown
                    options={options}
                    value={value}
                    onChange={setLanguage}
                />
            </>
        )
    }

    const renderCurrencyDropDown = () => {
        const options = currencyOptions.map((CurrencyItem) => ({
            value: CurrencyItem.value,
            label: CurrencyItem.description,
        }))

        const value =
            options.find((option) => option.value === currency) || null

        return (
            <>
                <FormLabel>
                    Currency
                    {requiredFieldsSet &&
                        preselectedCustomer?.status !==
                            CustomerStatus.COMPLETE && (
                            <span style={{ color: 'red' }}>
                                {' '}
                                - Cannot be edited
                            </span>
                        )}
                </FormLabel>
                <SelectDropdown
                    options={options}
                    value={value}
                    onChange={setCurrency}
                    isDisabled={
                        preselectedCustomer?.status === CustomerStatus.COMPLETE
                    }
                />
            </>
        )
    }

    const renderPhoneSection = () => {
        return (
            <>
                <FormLabel>
                    Phone number
                    <span> (optional)</span>
                </FormLabel>
                <PhoneInputField
                    {...{
                        phoneNumber: phoneNumber || '',
                        onChange: (phoneInput, errorMessage) => {
                            setPhoneNumber(phoneInput)
                            setPhoneError(errorMessage)
                        },
                        selectedCountry: countryCode as CountryCode,
                    }}
                />
                {phoneError && <ErrorMessage>{phoneError}</ErrorMessage>}
            </>
        )
    }

    const renderBillingInfo = () => {
        if (!countryCode) {
            return null
        }

        return (
            <BillingSection>
                {generateTextInputField(
                    address1,
                    setAddress1,
                    'Address field 1',
                )}
                {generateTextInputField(
                    address2,
                    setAddress2,
                    'Address field 2',
                    true,
                )}
                <MultipleSection>
                    <div>
                        {generateTextInputField(
                            zipCode,
                            setZipCode,
                            'Zip code / Postal code',
                        )}
                    </div>
                    <div>{generateTextInputField(city, setCity, 'City')}</div>
                </MultipleSection>
                {countryCode === 'US' &&
                    generateTextInputField(state, setState, 'State')}
                {renderPhoneSection()}
            </BillingSection>
        )
    }

    const renderDocumentsSection = () => {
        if (!preselectedCustomer) {
            return null
        }

        return (
            <CustomerDocumentTabWrapper>
                <Button
                    onClick={() => setShowUploadFileModal(!showUploadFileModal)}
                >
                    + Add
                </Button>
                <CustomerDocumentsTable
                    customerId={preselectedCustomer.id}
                    documents={documents}
                    refreshDocuments={refreshDocuments}
                />
            </CustomerDocumentTabWrapper>
        )
    }

    const renderTaxInformationSection = () => {
        const taxInformation = {
            selectedCountry: countryCode,
            preselectedTaxId: taxId,
            preselectedTaxType: taxType,
            preselectedTaxable: taxable,
            handleUpdateTaxId: (value: string) => setTaxId(value),
            handleUpdateTaxType: (value: string) => setTaxType(value),
            handleUpdateTaxable: (value: boolean) => setTaxable(value),
            handleUpdateTaxableValid: (value: boolean) =>
                setIsValidTaxId(value),
        }

        return <TaxInformationContainer {...taxInformation} />
    }

    const renderBillingInfoSection = () => {
        return (
            <>
                <FormLabel>Billing details</FormLabel>
                {renderCountryDropdown()}
                {renderBillingInfo()}
            </>
        )
    }

    const handleUpdateDocuments = () => {
        setShowUploadFileModal(false)
        setRefreshDocuments(!refreshDocuments)

        if (!preselectedCustomer) {
            return
        }

        getCustomerDocuments(preselectedCustomer?.id)
            .then((res: any) => {
                setDocuments(res.data.reverse())
            })
            .catch(() => {
                errorToast('Failed to fetch documents')
            })
    }

    const renderIncompleteCustomerAlert = () => {
        if (preselectedCustomer?.status === CustomerStatus.COMPLETE) return null

        return (
            <Alert
                variant="primary"
                dismissible
                onClose={() => setShowCustomerAlert(false)}
                show={showCustomerAlert}
            >
                <p>
                    Some required fields for a complete customer are missing.
                    You can save it as a draft and add the required information
                    at your convenience.
                </p>
            </Alert>
        )
    }

    const renderTitle = () => {
        if (preselectedCustomer) {
            return (
                <TabWrapper
                    className="modal-header"
                    activeKey={activeTab}
                    onSelect={(k: any) => setActiveTab(k || 'customer')}
                >
                    <Tab
                        title="Edit"
                        eventKey="customer"
                        id="0"
                        className="active"
                    />
                    <Tab title="Invoices" eventKey="invoices" id="1" />
                    <Tab title="Documents" eventKey="documents" id="2" />
                </TabWrapper>
            )
        }

        return 'New'
    }

    const renderBody = () => {
        return (
            <ModalWrapper>
                {activeTab === 'customer' && (
                    <>
                        <MultipleSection>
                            <div>
                                {renderNameField()}
                                {renderReferenceField()}
                            </div>
                            <div>
                                {renderExternalCustomerIdField()}
                                {renderEmailField()}
                            </div>
                        </MultipleSection>
                        {renderDescriptionField()}
                        {renderBillingInfoSection()}
                        <MultipleSection>
                            <div>{renderLanguageDropdown()}</div>
                            <div>{renderCurrencyDropDown()}</div>
                        </MultipleSection>
                        {renderTaxInformationSection()}
                        {renderIncompleteCustomerAlert()}
                    </>
                )}
                {activeTab === 'invoices' && preselectedCustomer && (
                    <InvoicesTable customer={preselectedCustomer} />
                )}
                {activeTab === 'documents' &&
                    preselectedCustomer &&
                    renderDocumentsSection()}
            </ModalWrapper>
        )
    }

    return (
        <>
            <Sidebar
                title={renderTitle()}
                body={renderBody()}
                onClose={onClose}
                onSubmit={handleSubmit}
                showSidebar={showSidebar}
                canSubmit={canSubmit}
                width="700px"
                hideFooter={activeTab !== 'customer'}
            />
            {showUploadFileModal && preselectedCustomer && (
                <FileUploadModal
                    onClose={() => setShowUploadFileModal(!showUploadFileModal)}
                    onUpdate={() => handleUpdateDocuments()}
                    customerId={preselectedCustomer.id}
                />
            )}
        </>
    )
}
