import { ListGroup, ListGroupItem } from 'react-bootstrap'

import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const ArticleList = styled(ListGroup)`
    width: 100%;
`

export const RadioButtons = styled.div`
    display: flex;
    justify-content: space-evenly;
    gap: 8px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
`

export const LicenseListItem = styled(ListGroupItem)`
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr) 0.1fr;
    gap: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: ${secondary.lightBlueGray};
    font-size: 1rem;

    @media screen and (max-width: 991px) {
        font-size: 0.8rem;
    }

    #info-section {
        display: contents;

        > * {
            display: flex;
            align-items: center;
        }

        #section-one {
            display: flex;
            flex-direction: column;
            align-items: start;
        }

        #description {
            height: fit-content;
            width: 100%;

            @media screen and (min-width: 991px) {
                font-size: 0.8rem;
            }
        }

        #price {
            justify-content: right;
        }

        #quantity {
            display: flex;
            align-self: center;
            justify-self: center;
            padding: 2px 10px;
            width: 60px;
        }
    }
`

export const TotalPriceSection = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
    font-weight: bold;
    margin-top: 8px;
    margin-right: 1rem;
`

export const PriceAdjustmentSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-right: 2rem;

    #price-adjustment {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: -0.5rem;
    }

    #price-adjustment-input {
        width: 100px;
        height: 30px;
    }

    #price-adjustment-info {
        color: ${primary.gray};
    }
`

export const ArticleSetWrapper = styled.div`
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    border: 0.5px solid ${secondary.lightBlueGray};
    border-radius: 0.5rem;
`

export const ArticlesSection = styled.div`
    font-size: 0.9rem;

    > .column-header {
        display: grid;
        grid-template-columns: 2fr repeat(3, 1fr) 0.1fr;
        gap: 1rem;

        #article-header {
            margin-left: 1em;
        }

        @media screen and (min-width: 991px) {
            #price-header {
                margin-left: 4em;
            }
        }

        > p {
            margin-bottom: 0.5rem;
        }
    }
`
