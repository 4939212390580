import React, { useEffect, useState } from 'react'

import { getCustomers } from 'api/customers'
import { getPartners } from 'api/partners'
import { deleteDocument, getDocuments } from 'api/vendors'
import { DeleteButton, KebabButton } from 'apps/shared/components/Buttons'
import DownloadPdfButton from 'apps/shared/components/Buttons/DownloadPdfButton'
import { EmptyListIcon } from 'apps/shared/components/Icons'
import { LoadingSpinner } from 'apps/shared/components/LoadingSpinner'
import { ConfirmModal } from 'apps/shared/components/Modals'
import NewItemButton from 'apps/shared/components/NewItemButton'
import SmartSearchAndFilter from 'apps/shared/components/SearchAndFilter/SmartSearchAndFilter'
import capitalizeAndFormat from 'apps/shared/utils/capitalizeAndFormat'
import { FileUploadModal } from 'apps/vendor/components/Modals'
import { VendorDocumentType } from 'apps/vendor/interfaces/documents'
import { CustomerType, PartnerType } from 'apps/vendor/interfaces/subscriptions'
import useToast from 'hooks/useToast'
import useVendor from 'hooks/useVendor'

import {
    ActionButtons,
    LibraryRow,
    LibraryBadgeRow,
    LibraryHeaderRow,
} from './DocumentLibrary.styled'

export default function DocumentLibraryPage() {
    const [documents, setDocuments] = useState<VendorDocumentType[]>([])
    const [partners, setPartners] = useState<PartnerType[]>([])
    const [customers, setCustomers] = useState<CustomerType[]>([])

    const [filteredDocuments, setFilteredDocuments] = useState<
        VendorDocumentType[]
    >([])
    const [loading, setLoading] = useState(true)
    const [showUploadFileModal, setShowUploadFileModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const [selectedDocument, setSelectedDocument] =
        useState<VendorDocumentType | null>(null)
    const { vendor } = useVendor()
    const { successToast, errorToast } = useToast()
    const [numberOfColumns, setNumberOfColumns] = useState(5)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1680) {
                setNumberOfColumns(4)
            } else {
                setNumberOfColumns(5)
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        setLoading(true)

        Promise.all([getPartners(), getCustomers(), getDocuments()])
            .then(([partnersRes, customersRes, documentRes]) => {
                setPartners(partnersRes.data)
                setCustomers(customersRes.data)
                setDocuments(documentRes.data)
                setLoading(false)
            })
            .catch(() => {
                errorToast('Error fetching data')
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenPdf = (document: VendorDocumentType) => {
        window.location.href = document.document_url
    }

    const handleUpdateDocuments = () => {
        setShowUploadFileModal(false)

        getDocuments()
            .then((res: any) => {
                setDocuments(res.data.reverse())
            })
            .catch(() => {
                errorToast('Failed to fetch documents')
            })
    }

    const handleDeleteModal = (document: VendorDocumentType) => {
        setShowDeleteModal(true)
        setSelectedDocument(document)
    }

    const handleDeleteDocument = () => {
        if (!selectedDocument) return

        deleteDocument(selectedDocument.id)
            .then(() => {
                successToast('Document deleted successfully')
                setSelectedDocument(null)
                setShowDeleteModal(false)

                setDocuments(
                    documents.filter((doc) => doc.id !== selectedDocument.id),
                )
            })
            .catch(() => {
                errorToast('Failed to delete document')
            })
    }

    const renderConfirmDeleteSection = () => {
        const relatedTo = selectedDocument ? selectedDocument.related_to : ''

        return (
            <>
                <section>
                    You are about to to delete the following article:
                    <p>{selectedDocument?.name}</p>
                    <p>
                        Related to: <b>{relatedTo}</b>
                    </p>
                </section>
                <section>
                    <b>This action cannot be undone.</b>
                </section>
            </>
        )
    }

    const renderDocumentTable = () => {
        if (!filteredDocuments || filteredDocuments.length === 0)
            return <EmptyListIcon />

        return (
            <>
                <LibraryHeaderRow>
                    <span>Name:</span>
                    <span>Related to:</span>
                    <span>Type:</span>
                    <span>Uploaded at:</span>
                    <span> </span>
                </LibraryHeaderRow>
                {filteredDocuments.map((document) => (
                    <LibraryRow key={document.id}>
                        <LibraryBadgeRow key={document.id} className="library">
                            <span>{document.name}</span>
                            <span>{document.related_to}</span>
                            <span>{capitalizeAndFormat(document.type)}</span>
                            <span>{document.uploaded_at}</span>
                            <ActionButtons className="flex-column">
                                {numberOfColumns === 5 ? (
                                    <>
                                        <DownloadPdfButton
                                            onClick={() =>
                                                handleOpenPdf(document)
                                            }
                                        />
                                        <DeleteButton
                                            onClick={() =>
                                                handleDeleteModal(document)
                                            }
                                        />
                                    </>
                                ) : (
                                    <KebabButton
                                        onDownload={() =>
                                            handleOpenPdf(document)
                                        }
                                        onDelete={() =>
                                            handleDeleteModal(document)
                                        }
                                    />
                                )}
                            </ActionButtons>
                        </LibraryBadgeRow>
                    </LibraryRow>
                ))}
            </>
        )
    }

    const renderDocumentLibraryContent = () => {
        return (
            <>
                <SmartSearchAndFilter
                    entities={documents}
                    properties={['type', 'related_to']}
                    handleSetFilteredEntities={setFilteredDocuments}
                />
                {loading ? <LoadingSpinner /> : renderDocumentTable()}
            </>
        )
    }

    if (!vendor) return null

    return (
        <div>
            <NewItemButton
                onButtonClicked={() =>
                    setShowUploadFileModal(!showUploadFileModal)
                }
            />
            {renderDocumentLibraryContent()}
            {showUploadFileModal && (
                <FileUploadModal
                    onClose={() => setShowUploadFileModal(!showUploadFileModal)}
                    onUpdate={() => handleUpdateDocuments()}
                    partners={partners}
                    customers={customers}
                />
            )}
            {showDeleteModal && (
                <ConfirmModal
                    onClose={() => {
                        setShowDeleteModal(!showDeleteModal)
                        setSelectedDocument(null)
                    }}
                    onSubmit={() => handleDeleteDocument()}
                    customSubmitText="Delete"
                    body={renderConfirmDeleteSection()}
                    deleteButton
                />
            )}
        </div>
    )
}
