import styled from 'styled-components'
import { primary, secondary } from 'utils/colors'

export const LibraryRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 2px 20px 2px 20px;
    border-bottom: 1px solid #e0e0e0;

    &:nth-child(even) {
        background-color: ${secondary.whisperWhite};
    }
`

export const ActionButtons = styled.div`
    all: unset;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    justify-content: end;
    align-items: center;
    height: 36px !important;

    > i {
        width: 20px;
    }
`
export const LibraryBadgeRow = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 2fr repeat(4, 1fr);

    > :nth-child(4) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: right;
    }

    > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 400px;
        align-self: center;
    }
`

export const LibraryHeaderRow = styled.div`
    padding: 20px;
    padding-right: 40px;
    color: ${primary.gray};
    font-size: 0.8rem;
    margin-bottom: -0.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr repeat(4, 1fr);

    > :nth-child(4) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: right;
    }

    .centered {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-align: center;
    }
`
